import { Link } from 'react-router-dom';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import { WorkspaceNameProps } from '@/types';

function WorkspaceName({
  singleProjectData,
  currentWorkspaceName,
  routes,
  organization_id,
  currentWorkspaceId,
  resetTabs,
}: WorkspaceNameProps) {
  return (
    <div className="flex gap-1">
      /
      <TooltipWrapper text="Back to workspace" className="text-xs">
        <Link
          onClick={resetTabs}
          className="transition-all ease-in-out hover:underline"
          to={`${routes.organization.path}/${organization_id}${routes.workspace.path}/${currentWorkspaceId}`}
        >
          {currentWorkspaceName}
        </Link>
      </TooltipWrapper>
      /
      <p>{singleProjectData?.title}</p>
    </div>
  );
}

export default WorkspaceName;
