import useTheme from 'chat-ui/src/hooks/useTheme';
import React from 'react';

function QuestionItemTitle({ children }: { children: React.ReactNode }) {
  const { currentTheme } = useTheme();

  return (
    <h3 style={{ color: currentTheme?.primary }} className="!mb-4 text-lg font-bold epilogue-font">
      {children}
    </h3>
  );
}

export default QuestionItemTitle;
