import { Link, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import UserIconDropDown from './UserIconDropDown';
import Routes from '@/router/routes';
import axiosInstance from '@/axios/axiosInstance';
import useTranslation from '@/hooks/useTranslation';
import ProjectButtonScenarios from './ProjectButtonScenarios';
import { ECompanyThemeName, EProjectStatus } from '@/enums';
import useGetCurrentWorkspace from '@/hooks/useGetCurrentWorkspace';
import useResultTabStore from '@/store/ResultsTabStore';
import { NavProps } from '@/types';
import WorkspaceName from '@/components/Navbar/WorkspaceName';
import NavBarLinks from '@/components/Navbar/NavBarLinks';

function ProjectNavbar({ user, orgData }: NavProps) {
  const location = useLocation();
  const { project_id, workspace_id, organization_id } = useParams();
  const { lang } = useTranslation();
  const { currentTheme, themeName } = useTheme();
  const { currentWorkspaceName, currentWorkspaceId, currentWorkspaceData } =
    useGetCurrentWorkspace();
  const { resetTabs } = useResultTabStore();

  const { data: singleProjectData } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (data) => data?.data,
    retry: 0,
  });

  const isSubscribed = orgData?.subscription?.subscribed;
  const isProjectDraft = singleProjectData?.status === EProjectStatus.DRAFT;
  const isDefaultTheme = themeName === ECompanyThemeName.DEFAULT;
  const isEmpathyTheme = themeName === ECompanyThemeName.EMPATHY;

  return (
    <nav
      style={{ backgroundColor: currentTheme?.background }}
      className="fixed left-0 right-0 flex h-[55px] items-center z-100 justify-between max-w-full gap-5 px-[2%] py-2 transition-all ease-in border-b"
    >
      <div className="flex items-center gap-2 text-[15px] w-fit whitespace-nowrap">
        <Avatar className="object-cover h-full -mr-2.5">
          {/* add img later */}
          <AvatarImage src="" className="object-cover max-w-[25px] max-h-[25px]" />
          <AvatarFallback
            style={{ backgroundColor: currentTheme?.['chat-primary'] }}
            className="h-7 w-7 font-semibold py-0.5 text-white"
          >
            {orgData?.name?.charAt(0).toUpperCase()}
          </AvatarFallback>
        </Avatar>

        {singleProjectData && currentWorkspaceData && (
          <WorkspaceName
            singleProjectData={singleProjectData}
            currentWorkspaceName={currentWorkspaceName}
            routes={Routes}
            organization_id={organization_id}
            currentWorkspaceId={currentWorkspaceId}
            resetTabs={resetTabs}
            currentWorkspaceData={currentWorkspaceData}
          />
        )}
      </div>
      <NavBarLinks
        resetTabs={resetTabs}
        organization_id={organization_id}
        workspace_id={workspace_id}
        project_id={project_id}
        lang={lang}
        routes={Routes}
        location={location}
        singleProjectData={singleProjectData}
        isEmpathyTheme={isEmpathyTheme}
      />

      <div className="flex items-center justify-end gap-4 w-fit">
        {isProjectDraft && !isSubscribed && isDefaultTheme && (
          <Link
            className="px-4 py-1.5 text-sm font-semibold text-white rounded-md bg-chat-primary hover:scale-[1.02] transition-all ease-in-out hover:bg-[#ed638a]"
            to={`${Routes.organization.path}/${organization_id}${Routes.choose_your_plan.path}`}
          >
            {lang.get('msg.upgradeNow')}
          </Link>
        )}

        <ProjectButtonScenarios />

        {/* user profile */}
        <button type="button" aria-label="user profile">
          <UserIconDropDown user={user} />
        </button>
      </div>
    </nav>
  );
}

export default ProjectNavbar;
