import { Link } from 'react-router-dom';
import Routes from '@/router/routes';
import { buttonVariants } from '@/components/ui/button';
import { VerifyEmailModalScreenProps } from '@/types';

function VerifyEmailModalScreen({
  title,
  children,
  linkText = 'Go to login',
}: VerifyEmailModalScreenProps) {
  const linkStyle = buttonVariants({
    variant: 'default',
    className: 'mt-5 transition-all ease-in-out hover:!bg-darker-primary',
  });

  return (
    <>
      <h1 className="text-xl font-bold">{title}</h1>
      <p className="text-[#5a6a85] leading-6">{children}</p>
      <Link className={linkStyle} to={Routes.login.path}>
        {linkText}
      </Link>
    </>
  );
}

export default VerifyEmailModalScreen;
