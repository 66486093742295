import { useContext, useEffect, useRef, useState } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import { LangContext } from '@/context/LangProvider';
import { ECompanyThemeName, EProjectStatus } from '@/enums';
import { cn } from '@/lib/utils';
import { ProjectShareProps } from '@/types';

function ProjectShareTestLink({ singleProjectData }: ProjectShareProps) {
  const { currentTheme, themeName } = useTheme();
  const { lang } = useContext(LangContext);
  const inputRefSandbox = useRef<HTMLInputElement>(null);
  const { project_id } = useParams();
  const [projectSandboxLink, setProjectSandboxLink] = useState('');
  const isDraft = singleProjectData?.status === EProjectStatus.DRAFT;
  const isPublished = singleProjectData?.status === EProjectStatus.PUBLISHED;
  const isCompleted = singleProjectData?.status === EProjectStatus.COMPLETED;
  const isReady = singleProjectData?.status === EProjectStatus.READY;

  const showInfoMessage = isPublished || isCompleted || isReady;

  const handleCopyClickSandbox = async () => {
    if (inputRefSandbox.current) {
      try {
        await navigator.clipboard.writeText(inputRefSandbox.current.value);
        toast({ description: `✅ ${lang.get('msg.linkCopied')}!` });
      } catch (err) {
        toast({ title: `${lang.get('msg.linkNotCopied')}`, variant: 'destructive' });
      }
    }
  };

  useEffect(() => {
    const SANDBOX_PROJECT_LINK = `${import.meta.env.VITE_ENVIRONMENT_CHAT}/${project_id}?environment=sandbox`;
    const SANDBOX_PROJECT_LINK_EMPATHY = `${import.meta.env.VITE_ENVIRONMENT_CHAT_EMPATHY}/${project_id}?environment=sandbox`;

    const linkSandbox = themeName === ECompanyThemeName.EMPATHY ? SANDBOX_PROJECT_LINK_EMPATHY : SANDBOX_PROJECT_LINK;

    setProjectSandboxLink(linkSandbox);
  }, [themeName, project_id]);

  return (
    <div className="flex flex-col w-full gap-5">
      {/* SANDBOX TEST CHAT */}
      <div className="flex flex-col gap-2">
        <div className="flex flex-col w-full gap-1">
          <p className="text-base font-medium">
            Test link
          </p>
          {/* info message */}
          {(isDraft || showInfoMessage) && (
            <p
              style={{ color: isPublished ? currentTheme?.['primary-text'] : '' }}
              className={cn(
                'text-sm flex items-center gap-1.5 font-medium',
                isDraft && 'text-red-600',
              )}
            >
              <IconInfoCircle
                size={17}
                className={cn(isDraft && 'text-red-600')}
                style={{ color: showInfoMessage ? currentTheme?.primary : '' }}
              />
              {isDraft && lang.get('msg.someFieldsStillNeedToBeCompletedForYourInterviewToBeReady')}
              {showInfoMessage && lang.get('msg.thisTestLinkIsSolelyForTestingPurposes')}
            </p>
          )}
        </div>

        <div
          style={{ backgroundColor: currentTheme?.['secondary-background'] }}
          className="flex flex-col items-center justify-center w-full gap-6 p-4 rounded-md"
        >
          <div className="flex w-full">
            <Input
              type="text"
              ref={inputRefSandbox}
              readOnly
              defaultValue={projectSandboxLink}
              className="rounded-tr-none rounded-br-none"
            />
            <Button
              variant="default"
              onClick={handleCopyClickSandbox}
              className="border-none rounded-tl-none rounded-bl-none"
            >
              Copy test link
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectShareTestLink;
