import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import ChatPresentation from 'chat-ui/ChatPresentation';
import useChatStore from 'chat-ui/src/store';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import useChatSettingsStore from 'chat-ui/src/ChatSettingsStore';
import { EStatusCode } from '@/enums';
import axiosInstanceChat, { getTestChatHistory, testChatRegister } from './axios/axiosInstanceChat';
import { ChatProps, TestChatRegisterData } from '@/types';

const chatCannotRegisterError = 'We cannot find this chat. Please check the link or contact the owner of the chat.';
const errorSendingMessage = "We can't send your message. Please try again.";
const incompleteChatSetupWarning = 'Please ensure that both the opening message and the first question are filled out before initiating the test conversation.';

function DashboardTestChat({ projectId, chatHeight, singleProjectData }: ChatProps) {
  const {
    setInitialMessages,
    setCurrentProjectToken,
    currentProjectToken,
    setSeen,
    addErrorMessage,
    setRefreshChatHistory,
    noSpeechDetected,
    setNoSpeechDetected,
  } = useChatStore((state) => state);
  const current_conversation_id = localStorage.getItem(`current_conversation_id/test/${projectId}`);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const { setChatSettingsData } = useChatSettingsStore();

  const { data: themeData } = useQuery({
    queryFn: () => axios.get(`${import.meta.env.VITE_API_BASE_URL}/projects/get/${projectId}/theme`),
    queryKey: ['chat_themes_branding', { projectId }],
    enabled: !!projectId,
    select: (data) => data?.data,
  });

  const handleChatRegister = useCallback(
    async (onError?: () => void) => {
      try {
        const data: TestChatRegisterData = await testChatRegister(projectId);
        if (data?.token) {
          setCurrentProjectToken(data?.token);
        } else {
          setCurrentProjectToken('');
        }

        setChatSettingsData(singleProjectData?.type_of_interview as string[]);
        localStorage.setItem(`chat_settings_${projectId}`, String(singleProjectData?.type_of_interview));
      } catch (error) {
        onError?.();
      }
    },
    [projectId, setCurrentProjectToken, setChatSettingsData, singleProjectData],
  );

  const getHistoryAndSetInitMsg = useCallback(async () => {
    if (currentProjectToken && current_conversation_id) {
      try {
        const data = await getTestChatHistory(currentProjectToken);
        setInitialMessages(data);
        setSeen();
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError && axiosError.response?.status !== EStatusCode.INVALID_CREDENTIALS) {
          addErrorMessage(errorSendingMessage);
        }
      }
    }
  }, [currentProjectToken, projectId]);

  useEffect(() => {
    setRefreshChatHistory(() => getHistoryAndSetInitMsg());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectToken]);

  const handleRestartChat = useCallback(async () => {
    setIsRefreshLoading(true);
    try {
      if (current_conversation_id && current_conversation_id !== 'undefined') {
        const response = await axiosInstanceChat.post(
          `${import.meta.env.VITE_CHAT_URL}/chat/sandbox/reset/${projectId}/${current_conversation_id}`,
          undefined,
          {
            headers: {
              Authorization: `Bearer ${currentProjectToken}`,
            },
          },
        );
        setIsRefreshLoading(false);
        setInitialMessages(response.data.messages);
      } else {
        setIsRefreshLoading(false);
        toast.info(incompleteChatSetupWarning);
      }
    } catch (error) {
      setIsRefreshLoading(false);
      addErrorMessage(errorSendingMessage);
    }
  }, [projectId, current_conversation_id]);

  useEffect(() => {
    if (projectId) {
      handleChatRegister(() => addErrorMessage(chatCannotRegisterError));
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProjectToken && current_conversation_id) {
      getHistoryAndSetInitMsg();
    }
  }, [currentProjectToken, current_conversation_id]);

  useEffect(() => {
    if (noSpeechDetected) {
      const fetchHistory = async () => {
        await getHistoryAndSetInitMsg();
        setNoSpeechDetected(false);
      };

      fetchHistory();
    }
  }, [getHistoryAndSetInitMsg, noSpeechDetected, setNoSpeechDetected]);

  useEffect(() => {
    const chatSettings = localStorage.getItem(`chat_settings_${projectId}`);
    const chatSettingsFormatted = chatSettings?.split(',');
    setChatSettingsData(chatSettingsFormatted as string[]);
  }, [setChatSettingsData, projectId]);

  return (
    <ChatPresentation
      chatHeight={chatHeight}
      handleRestartChat={handleRestartChat}
      restartChatButton
      isTestChat
      isRefreshLoading={isRefreshLoading}
      themeData={themeData}
    />
  );
}

export default DashboardTestChat;
