import { cn } from '@/lib/utils';
import { Skeleton } from '@/components/ui/skeleton';
import { StatisticCardProps } from '@/types';

function StatisticCard({ isLoading, children, title, bgColor }: StatisticCardProps) {
  if (isLoading) {
    return <Skeleton className="w-full h-40 rounded-2xl" />;
  }

  return (
    <div
      style={{ backgroundColor: bgColor }}
      className={cn(
        'flex flex-col w-full h-40 py-5 px-7 justify-between text-white rounded-2xl',
        !bgColor && 'bg-slate-500',
      )}
    >
      <h4 className="font-medium">{title}</h4>
      <span className="text-4xl font-semibold">{children}</span>
    </div>
  );
}

export default StatisticCard;
