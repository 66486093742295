import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ToolTip from '@/components/tooltip/ToolTip';
import { LangContext } from '@/context/LangProvider';
import { useMutateProjectUpdate } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import { EProjectStatus } from '@/enums';
import { Input } from '@/components/ui/input';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { MaxRespondentsFormValues, ProjectShareMaxRespondentsProps } from '@/types';

const createValidationSchema = (min: number, isProjectPublished: boolean) => {
  return Yup.object().shape({
    respondents_max: Yup.number()
      .required('This field is required')
      .min(
        isProjectPublished ? (min > 1 ? min + 1 : min) : 1,
        isProjectPublished
          ? `Number must be greater than ${min > 1 ? min : 'or equal to ' + min}`
          : 'Number must be greater than 0',
      )
      .max(5000, 'Number must be less than or equal to 5000')
      .nullable()
      .typeError('This field is required'),
  });
};

function ProjectShareMaxRespondets({ singleProjectData }: ProjectShareMaxRespondentsProps) {
  const { lang } = useContext(LangContext);
  const { project_id, workspace_id } = useParams();
  const isProjectCompleted = singleProjectData?.status === EProjectStatus.COMPLETED;
  const isProjectPublished = singleProjectData?.status === EProjectStatus.PUBLISHED;
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const [isDataSaved, setIsDataSaved] = useState(isProjectPublished);
  const minNumber = singleProjectData?.respondents_max || 1;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { respondents_max: singleProjectData?.respondents_max || null },
    resolver: yupResolver(createValidationSchema(minNumber, isProjectPublished)),
    mode: 'onSubmit',
  });
  const { mutateAsync, isPending } = useMutateProjectUpdate();

  const onSubmit: SubmitHandler<MaxRespondentsFormValues> = async (
    data: MaxRespondentsFormValues,
  ) => {
    try {
      await mutateAsync({
        _id: project_id,
        ...data,
      });
      if (isProjectPublished) {
        toast({ description: lang.get('msg.maxNumberOfRespondetsUpdatedSuccessfully') });
        setIsDataSaved(true);
      }
    } catch (error) {
      toast({ description: lang.get('msg.errorPleaseTryAgain') });
    }
  };

  const respondentsMax = watch('respondents_max');

  useEffect(() => {
    if (respondentsMax) {
      setIsDataSaved(false);
    }
  }, [respondentsMax]);

  const isRespondentsMaxChanged = respondentsMax !== singleProjectData?.respondents_max;
  const isButtonDisabled =
    (isDataSaved || isPending || !isRespondentsMaxChanged) && isProjectPublished;

  return (
    <div className="w-full h-full">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full gap-6 pb-10">
        <label className="flex flex-col gap-2">
          <div className="flex items-center gap-1">
            <ToolTip text="Add max number of the respondents" className="ml-5" />
            <p className="flex items-center gap-1 text-base font-medium">
              {lang.get('msg.maximumNumberOfRespondets')}
              <span className="text-red-600">*</span>
            </p>
          </div>
          <div className="flex items-center w-full gap-3">
            <Input
              type="number"
              disabled={isUserViewer || isProjectCompleted || isPending}
              {...register('respondents_max')}
              placeholder="Add maximum number of the respondents"
              hasError={!!errors?.respondents_max?.message}
            />
            <Button disabled={isButtonDisabled} type="submit" variant="default" className="px-6">
              Save
            </Button>
          </div>
          <p
            className={cn(
              errors?.respondents_max?.message ? 'flex' : 'hidden',
              'text-red-600 text-xs font-semibold',
            )}
          >
            {errors?.respondents_max?.message as string}
          </p>
        </label>
      </form>
    </div>
  );
}

export default ProjectShareMaxRespondets;
