import { IconLoader2 } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import QuestionAnswersTable from './QuestionAnswersTable';
import { TranscriptsProps } from '@/types';

function Transcripts({ isReady, questionID, selectedTranscripts }: TranscriptsProps) {
  const { currentTheme } = useTheme();

  return (
    <div className="w-full py-4">
      {isReady ? (
        <QuestionAnswersTable
          questionID={questionID}
          selectedQuestionTranscripts={selectedTranscripts}
        />
      ) : (
        <div className="flex items-center justify-center w-full py-20 mx-auto">
          <IconLoader2
            style={{ color: currentTheme?.primary }}
            className="animate-spin"
            size={35}
          />
        </div>
      )}
    </div>
  );
}

export default Transcripts;
