import { Controller } from 'react-hook-form';
import { Input } from '@/components/ui/input';
import { TextFieldProps } from '@/types';

function TextField({
  control,
  name,
  children,
  type = 'text',
  errorMsgPosition = 'top',
  required = false,
}: TextFieldProps) {
  const msgPosition =
    errorMsgPosition === 'top' ? 'top-1  right-2' : '-bottom-1 left-0 transform translate-y-full';
  const isOrganisationName = name === 'organisation_name';
  return (
    <div className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <label htmlFor={name} className="relative">
              {children}
              {required && <span className="absolute top-0 -right-2 block text-red-500">*</span>}
            </label>
            <Input
              {...field}
              id={name}
              hasError={!!error}
              type={type}
              onChange={(e) => {
                if (isOrganisationName && e.target.value.length > 50) {
                  return;
                }
                field.onChange(e);
              }}
            />
            {error?.message && (
              <p className={`absolute ml-1 text-xs text-left text-red-600 ${msgPosition}`}>
                {error.message}
              </p>
            )}
            {isOrganisationName && (
              <p className="text-xs self-end text-light-dark">
                {50 - field.value.length} Characters left
              </p>
            )}
          </>
        )}
      />
    </div>
  );
}

export default TextField;
