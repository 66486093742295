import { IconEyePlus, IconLoader2, IconMovie, IconPhotoFilled, IconUpload } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import RequiredLabel from '@/components/labels/RequiredLabel';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import useTranslation from '@/hooks/useTranslation';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import QuestionMediaPreview from './QuestionMediaPreview';
import useCreateProjectStore from '@/store/CreateProjectStore';
import getTextColor from '@/utils/getTextColorContrast';
import { MediaUploadingProps } from '@/types';

const MAX_FILE_SIZE = 1024 * 1024 * 200;

function QuestionMediaUploading({ id, isProjectCompleted, isProjectPublished, index, setFileData, singleProjectData }: MediaUploadingProps) {
  const { lang } = useTranslation();
  const { currentTheme } = useTheme();
  const { workspace_id } = useParams();
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const { setIsFormChanged, isMediaUploading } = useCreateProjectStore();
  const [openMediaPreview, setOpenMediaPreview] = useState(false);
  const [mediaPreview, setMediaPreview] = useState<string | undefined>('');
  const { setIsMediaChanged } = useCreateProjectStore();

  const showImagePreview = () => {
    const imageURL: string | undefined = singleProjectData?.interview_questions?.[index]?.media?.url;
    setMediaPreview(imageURL);

    if (imageURL) setOpenMediaPreview(true);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file?.size > MAX_FILE_SIZE) {
      toast({ description: lang.get('msg.maximum200MBfileError'), variant: 'destructive' });
      return;
    }

    setFileData((prevFileData) => ({
      ...prevFileData,
      [id]: {
        ...prevFileData[id],
        file,
      },
    }));
    setIsFormChanged(true);
    setIsMediaChanged(true);
  };

  return (
    <div>
      <RequiredLabel title="uploadMedia" tooltip={lang.get('msg.uploadMediaInfo')}>
        <div className="flex flex-col items-center w-full gap-4">
          <div className="flex items-center w-full gap-1.5 cursor-pointer">
            <IconUpload
              size={25}
              style={{ backgroundColor: currentTheme?.primary,
                color: getTextColor(currentTheme?.primary || ''),
              }}
              className="w-11 h-[38px] p-1.5 rounded-md"
            />
            <Input
              accept="image/jpeg, image/jpg, video/mp4, audio/mp3, audio/wav, image/png, image/webp, image/gif"
              onChange={handleFileChange}
              disabled={isUserViewer || isProjectPublished || isProjectCompleted || isMediaUploading}
              type="file"
              className="w-full cursor-pointer"
            />
          </div>
        </div>
      </RequiredLabel>

      {isMediaUploading
        ? (
          <div className="flex items-center justify-center w-full gap-2 px-4 py-2 mt-3 border-2 border-dashed rounded-sm bg-primary/20 border-primary">
            <IconLoader2 size={20} className="animate-spin" />
            <p className="text-sm font-semibold text-primary">
              {lang.get('msg.uploading')}
              ...
            </p>
          </div>
        )
        : (
          <button onClick={showImagePreview} type="button" className="flex flex-col w-full gap-1.5 mt-3">
            {singleProjectData?.interview_questions?.[index]?.media && (
              <p className="relative flex items-center w-full gap-1 py-2 pl-4 pr-8 text-sm font-semibold border-2 border-dashed rounded-sm text-primary border-primary bg-primary/20">
                {singleProjectData?.interview_questions?.[index]?.media?.type === 'video/mp4'
                  ? <IconMovie size={20} className="mr-1 min-w-5" />
                  : <IconPhotoFilled size={20} className="mr-1 min-w-5" />}
                <span className="overflow-hidden max-w-[460px] truncate">
                  {singleProjectData?.interview_questions?.[index]?.media?.name}
                </span>
                <IconEyePlus size={20} className="absolute -translate-y-1/2 right-2 top-1/2 text-primary" />
              </p>
            )}
          </button>
        )}
      {/* image preview */}
      {openMediaPreview && (
        <QuestionMediaPreview
          setOpenMediaPreview={setOpenMediaPreview}
          mediaPreview={mediaPreview}
          setMediaPreview={setMediaPreview}
        />
      )}
    </div>
  );
}

export default QuestionMediaUploading;
