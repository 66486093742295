import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteWorkspace from '@/components/Modals/DeleteWorkspaceModal';
import RenameWorkspaceModal from '@/components/Modals/RenameWorkspaceModal';
import WorkspaceInviteModal from '@/components/Modals/WorkspaceInviteModal';
import MyWorkspaceTable from '@/components/all-tellets-table/MyWorkspaceTable';
import FullPageLoader from '@/components/atoms/Loader/FullPageLoader';
import EllipsisHorizontalIcon from '@/components/icons/EllipsisHorizontalIcon';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import useGetAllWorkspaces from '@/hooks/useGetAllWorkspaces';
import useGetCurrentWorkspace from '@/hooks/useGetCurrentWorkspace';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import useErrorStore from '@/store/ErrorStore';
import { EStatusCode } from '@/enums';
import FreemiumTooltip from '@/components/tooltip/FreemiumTooltip';

function MyWorkspace() {
  const navigate = useNavigate();
  const { workspace_id } = useParams();
  const { firstWorkspaceInList, isWorkspaceLoading } = useGetAllWorkspaces();
  const setShowError = useErrorStore((state) => state.setShowError);
  const { isUserOwner, isUserEditor } = useWorkspaceRoles(workspace_id);
  const {
    currentWorkspaceName,
    currentWorkspaceId,
    currentWorkspaceError,
    isCurrentWorkspaceLoading,
  } = useGetCurrentWorkspace();

  const axiosError = currentWorkspaceError as AxiosError;

  useEffect(() => {
    if (
      axiosError?.status === EStatusCode.BAD_REQUEST ||
      axiosError?.status === EStatusCode.WORKSPACE_NOT_FOUND
    ) {
      setShowError(true);
    }
  }, [axiosError, navigate, setShowError]);

  if (isCurrentWorkspaceLoading || isWorkspaceLoading) return <FullPageLoader />;

  const canUserEdit = isUserOwner || isUserEditor;

  return (
    <main className="flex flex-col w-full h-full">
      <FreemiumTooltip />

      <div className="flex items-center gap-7">
        <h1 className="text-xl font-semibold epilogue-font">{currentWorkspaceName}</h1>
        <div className="flex items-center gap-1">
          <WorkspaceInviteModal
            firstWorkspaceInList={firstWorkspaceInList}
            currentWorkspace={workspace_id}
          />
          <DropdownMenu>
            <DropdownMenuTrigger asChild disabled={!canUserEdit}>
              <Button type="button" className="-mb-1" variant="ghost">
                <EllipsisHorizontalIcon className="size-6" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-52 z-100">
              {(isUserOwner || isUserEditor) && (
                <RenameWorkspaceModal
                  currentWorkspaceName={currentWorkspaceName}
                  currentWorkspaceId={currentWorkspaceId}
                />
              )}
              {isUserOwner && (
                <DeleteWorkspace
                  currentWorkspaceName={currentWorkspaceName}
                  currentWorkspaceId={currentWorkspaceId}
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <MyWorkspaceTable currentWorkspace={workspace_id} />
    </main>
  );
}

export default MyWorkspace;
