import CountUp from 'react-countup';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import axiosInstance from '@/axios/axiosInstance';
import {
  translateAvgTimeToCompletion,
  translateCompletedInterviews,
  translateInterviewsInProgress,
  translateCompletionRate,
  translateStatistics,
  translateTotal,
} from '../project_results/resultsPageTranslations';
import { StatisticsProps } from '@/types';
import StatisticCard from './StatisticCard';

function Statistics({ data, isLoading, project_id }: StatisticsProps) {
  const { currentTheme } = useTheme();

  const { data: singleProjectData } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (projData) => projData?.data,
  });

  const analysisLanguage = singleProjectData?.analysis_language;

  const totalSeconds = Math.floor((data?.average_time as number) / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;
  // Add leading zeros if needed
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-start justify-between w-full">
        <h3
          style={{ color: currentTheme?.primary }}
          className="!mb-6 text-lg font-bold epilogue-font"
        >
          {translateStatistics[analysisLanguage] || translateStatistics.English} (
          {translateTotal[analysisLanguage] || translateTotal.English})
        </h3>
      </div>
      <div className="grid grid-cols-4 gap-4 max1100:grid-cols-3 md800:grid-cols-2 min500:grid-cols-1">
        {/* completed interviews */}
        <StatisticCard
          isLoading={isLoading}
          bgColor={currentTheme?.primary}
          title={
            translateCompletedInterviews[analysisLanguage] || translateCompletedInterviews.English
          }
        >
          <CountUp end={data?.completed_interviews} />
        </StatisticCard>
        {/* started interviews */}
        <StatisticCard
          isLoading={isLoading}
          title={
            translateInterviewsInProgress[analysisLanguage] || translateInterviewsInProgress.English
          }
        >
          <CountUp end={data?.started_interviews} />
        </StatisticCard>
        {/* completion rate */}
        <StatisticCard
          isLoading={isLoading}
          title={translateCompletionRate[analysisLanguage] || translateCompletionRate.English}
        >
          <CountUp end={data?.completion_rate} /> %
        </StatisticCard>

        {/* avg time to completion */}
        <StatisticCard
          isLoading={isLoading}
          title={
            translateAvgTimeToCompletion[analysisLanguage] || translateAvgTimeToCompletion.English
          }
        >
          {+formattedHours > 0 ? <CountUp end={+formattedHours} /> : 0}h :
          {+formattedMinutes ? <CountUp end={+formattedMinutes} /> : 0}m :
          {+formattedSeconds < 10 && '0'}
          <CountUp end={Number(formattedSeconds)} />s
        </StatisticCard>
      </div>
    </div>
  );
}

export default Statistics;
