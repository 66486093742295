import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { cn } from '@/lib/utils';
import { DefaultFormFieldProps } from '@/types';
import 'react-phone-number-input/style.css';

function TelephoneField({ control, name }: DefaultFormFieldProps) {
  return (
    <div className="flex text-[#2a3547] flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <label htmlFor={name}>Phone number</label>
            <PhoneInput
              {...field}
              id={name}
              className={cn(
                'rounded-md border border-natural-700 transition-all gap-2  flex h-10 w-full shadow-sm text-gray-700 focus-within:text-gray-900 bg-gray-50 px-3.5 py-2.5 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 focus-visible:outline-none focus-visible:shadow-fc-secondary disabled:pointer-events-none disabled:bg-gray-200 disabled:text-gray-400 invalid:shadow-hv-error invalid:bg-white invalid:text-gray-900',
                !!error?.message && 'border-red-600',
              )}
              defaultCountry="NL"
              international
              countryCallingCodeEditable={false}
            />
            {error?.message && (
              <p className="absolute ml-1 text-xs text-left text-red-600 -top-3 right-2 transform translate-y-full">
                {error.message}
              </p>
            )}
          </>
        )}
      />
    </div>
  );
}

export default TelephoneField;
