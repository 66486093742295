import { useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Button } from '../ui/button';
import EnglishIcon from '@/assets/country_flags/english.svg';
import DutchIcon from '@/assets/country_flags/dutch.svg';
import { LanguageDropdownList, LanguageDropdownProps } from '@/types';

const languageList: LanguageDropdownList[] = [
  {
    value: 'en',
    label: 'English (UK)',
    icon: (
      <img
        src={EnglishIcon}
        alt="English Icon"
        className="object-cover w-full h-full rounded-full"
      />
    ),
  },
  {
    value: 'nl',
    label: 'Dutch',
    icon: (
      <img src={DutchIcon} alt="English Icon" className="object-cover w-full h-full rounded-full" />
    ),
  },
];

function LanguageDropDown({ onLanguageChange, selectedLanguage }: LanguageDropdownProps) {
  const [pickedLanguage] = useState<string>(selectedLanguage);

  const activeLanguage =
    languageList.find(({ value }) => value === selectedLanguage) || languageList[0];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex items-center gap-1">
          <Button
            variant="ghost"
            className="w-[38px] hover:bg-secondary/20 h-[38px] p-2 border-none rounded-full outline-none select-none ring-offset-transparent ring-transparent focus-visible:ring-0 focus-visible:shadow-none focus-visible:ring-opacity-0"
          >
            {activeLanguage.icon}
          </Button>
          <p className="cursor-pointer">{activeLanguage.label}</p>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="relative w-52 z-60">
        <DropdownMenuRadioGroup className="flex flex-col gap-0.5 " value={pickedLanguage}>
          {languageList.map((item) => {
            const { value, icon, label } = item;
            return (
              <DropdownMenuRadioItem
                key={value}
                className={`flex items-center gap-2 px-5 py-3 cursor-pointer ${
                  selectedLanguage === value && 'bg-neutral-100'
                }`}
                value={value}
                onClick={() => onLanguageChange(value)}
              >
                <span className="w-6 h-6 rounded-full">{icon}</span>
                {label}
              </DropdownMenuRadioItem>
            );
          })}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default LanguageDropDown;
