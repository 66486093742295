import { ComponentPropsWithoutRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { MarkdownProps } from '@/types';

function MarkdownUl(props: ComponentPropsWithoutRef<'ul'>) {
  return <ul className="list-disc pl-4" {...props} />;
}

function Markdown({ isParagraphSpanEl, children, ...rest }: MarkdownProps) {
  if (!children) return null;

  return (
    <ReactMarkdown
      components={{
        p: isParagraphSpanEl ? 'span' : 'p',
        ul: MarkdownUl,
      }}
      {...rest}
    >
      {children}
    </ReactMarkdown>
  );
}

export default Markdown;
