import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import PhoneInput from 'react-phone-number-input';
import { IconCircleCheck } from '@tabler/icons-react';
import useTranslation from '@/hooks/useTranslation';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useGetUserData } from '@/reactQuery/get';
import { toast } from '@/components/ui/use-toast';
import { useMutationsUpdateUser } from '@/reactQuery/post';
import LanguageDropDown from '@/components/Navbar/LanguageDropDown';
import { EStatusCode } from '@/enums';
import { cn } from '@/lib/utils';

const defaultValues = {
  name: '',
  language: '',
  phone_number: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('nameIsRequired'),
  phone_number: Yup.string()
    .required('phoneNumberIsRequired')
    .min(8, 'invalidPhoneNumber')
    .max(20, 'invalidPhoneNumber'),
  language: Yup.string().required('languageIsRequired'),
});

type TMyProfile = typeof defaultValues;

function ProfileEditForm() {
  const { data: user } = useGetUserData();
  const { lang } = useTranslation();
  const initial = useRef(true);
  const [updateProfileError, setUpdateProfileError] = useState<string>('');
  const { mutateAsync } = useMutationsUpdateUser();
  const [isAllSaved, setIsAllSaved] = useState<boolean>(false);
  const { currentTheme } = useTheme();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors, isSubmitting, dirtyFields, isDirty },
  } = useForm<TMyProfile>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  useEffect(() => {
    if (user) {
      const { phone_number, name, language } = user;
      setValue('phone_number', phone_number);
      setValue('name', name);
      setValue('language', language);
    }
  }, [setValue, user]);

  useEffect(() => {
    if (isDirty) {
      setIsAllSaved(false);
    }
  }, [isDirty]);

  const onSubmit = async (data: TMyProfile) => {
    try {
      setUpdateProfileError('');
      const { status } = await mutateAsync(data);
      if (status === EStatusCode.CREATED) {
        toast({
          title: `✅ ${lang.get('msg.profileUpdated')}`,
          description: lang.get('msg.profileUpdatedDescription'),
        });
        reset({
          ...data,
        });
        initial.current = false;
        setIsAllSaved(true);
      }
    } catch (error) {
      setUpdateProfileError(lang.get('msg.updateProfileError'));
    }
  };

  const hasChangesInForm =
    Object.keys(dirtyFields).length > 0 ||
    Object.keys(errors).length > 0 ||
    (!isAllSaved && (!initial.current || isDirty));
  const isButtonDisabled = isSubmitting || !hasChangesInForm || Object.keys(errors).length > 0;

  // eslint-disable-next-line no-nested-ternary
  const submitButtonText = isAllSaved
    ? lang.get('msg.allSaved')
    : hasChangesInForm
      ? lang.get('msg.updateProfile')
      : lang.get('msg.noChanges');

  return (
    <>
      <h2 className="text-lg font-semibold">{lang.get('msg.profile')}</h2>
      <p
        style={{ color: currentTheme?.['secondary-text'] }}
        className="mt-1 mb-6 text-sm text-light-gray"
      >
        {lang.get('msg.profileSubtitle')}
      </p>
      <hr />
      <form
        className="h-full max-w-lg mt-5 flex flex-col items-start"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
          <label style={{ color: currentTheme?.['primary-text'] }}>{lang.get('msg.name')}</label>
          <Input {...register('name')} hasError={!!errors.name} type="text" />
          {errors.name?.message && (
            <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
              {lang.get(`msg.${errors.name?.message}`)}
            </p>
          )}
          <p style={{ color: currentTheme?.['secondary-text'] }} className="text-xs">
            {lang.get('msg.nameInfoMessage')}
          </p>
        </div>

        <div className="flex mt-4  flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
          <label style={{ color: currentTheme?.['primary-text'] }}>{lang.get('msg.email')}</label>
          <div className="peer rounded-md border border-natural-700 transition-all flex h-10 w-full shadow-sm focus-within:text-gray-900 px-3.5 py-2.5 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 focus-visible:outline-none focus-visible:shadow-fc-secondary pointer-events-none bg-gray-100 text-gray-400 invalid:shadow-hv-error invalid:bg-white invalid:text-gray-900">
            {user?.email || '--'}
          </div>
          <p style={{ color: currentTheme?.['secondary-text'] }} className="text-xs">
            {lang.get(
              'msg.ifYouWouldLikeToChangeTheEmailAddressAssociatedWithYourProfilePleaseReachOut',
            )}{' '}
            <a className="font-bold underline" href="mailto:info@tellet.ai">
              info@tellet.ai
            </a>
            .
          </p>
        </div>

        <div className="flex mt-4 flex-col gap-2 items-start text-sm font-semibold leading-[1.5] relative items-left content-left">
          <label style={{ color: currentTheme?.['primary-text'] }}>
            {lang.get('msg.language')}
          </label>
          <Controller
            name="language"
            control={control}
            render={({ field: { value, onChange } }) => {
              const selectedLanguage = value || localStorage.getItem('selectedLanguage') || 'en';
              return (
                <LanguageDropDown
                  selectedLanguage={selectedLanguage}
                  onLanguageChange={(newLanguage: string) => onChange(newLanguage)}
                />
              );
            }}
          />
          <p style={{ color: currentTheme?.['secondary-text'] }} className="text-xs">
            {lang.get('msg.clickAndSelectLanguageForYourProfile')}
          </p>
        </div>

        <div className="flex mt-4  flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative">
          <label style={{ color: currentTheme?.['primary-text'] }}>
            {lang.get('msg.phoneNumber')}
          </label>
          {errors.phone_number?.message && (
            <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
              {lang.get(`msg.${errors.phone_number?.message}`)}
            </p>
          )}
          <Controller
            name="phone_number"
            control={control}
            render={({ field: { value, onChange } }) => (
              <PhoneInput
                className={cn(
                  'rounded-md border border-natural-700 transition-all gap-2 flex h-10 w-full shadow-sm text-gray-700 focus-within:text-gray-900 bg-gray-50 px-3.5 py-2.5 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 focus-visible:outline-none focus-visible:shadow-fc-secondary disabled:pointer-events-none disabled:bg-gray-200 disabled:text-gray-400 invalid:shadow-hv-error invalid:bg-white invalid:text-gray-900',
                  !!errors.phone_number && 'border-red-600',
                )}
                placeholder="Enter phone number"
                value={value}
                onChange={onChange}
                defaultCountry="NL"
                international
                countryCallingCodeEditable={false}
              />
            )}
          />
          <p style={{ color: currentTheme?.['secondary-text'] }} className="text-xs">
            {lang.get('msg.changePhoneNumberSubtitle')}
          </p>
          {updateProfileError && (
            <p className="absolute right-0 text-sm text-red-600 -bottom-4">
              {lang.get('msg.unableToUpdateProfile')}
            </p>
          )}
        </div>

        <Button variant="default" disabled={isButtonDisabled} className="mt-6">
          {isAllSaved && <IconCircleCheck className="mr-1.5" size={20} />}
          {submitButtonText}
        </Button>
      </form>
    </>
  );
}

export default ProfileEditForm;
