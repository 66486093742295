import { useForm } from 'react-hook-form';
import { useState } from 'react';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { toast } from '../ui/use-toast';
import axiosInstance from '@/axios/axiosInstance';
import useTranslation from '@/hooks/useTranslation';
import { EStatusCode } from '@/enums';
import { Input } from '../ui/input';
import { Button } from '../ui/button';

const defaultValues = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, 'passwordMustHaveAtLeastEightCharacters')
    .matches(/[A-Z]/, 'passwordNeedsAtLeastOneUppercaseLetter')
    .matches(/[a-z]/, 'passwordNeedsAtLeastOneLowercaseLetter')
    .matches(/[0-9]/, 'passwordNeedsAtLeastOneNumber')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'passwordNeedsAtLeastOneSpecialCharacter')
    .required('passwordIsRequired'),
  newPassword: Yup.string()
    .min(8, 'passwordMustHaveAtLeastEightCharacters')
    .matches(/[A-Z]/, 'passwordNeedsAtLeastOneUppercaseLetter')
    .matches(/[a-z]/, 'passwordNeedsAtLeastOneLowercaseLetter')
    .matches(/[0-9]/, 'passwordNeedsAtLeastOneNumber')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'passwordNeedsAtLeastOneSpecialCharacter')
    .required('passwordIsRequired'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'passwordsMustMatch')
    .required('thisFieldIsRequired'),
});
type TPasswordChange = typeof defaultValues;

function PasswordEditForm() {
  const [changePasswordError, setChangePasswordError] = useState<string>('');
  const { lang } = useTranslation();
  const { currentTheme } = useTheme();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<TPasswordChange>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onPasswordSubmit = async (data: TPasswordChange) => {
    try {
      setChangePasswordError('');
      await axiosInstance.post('/users/change_password', {
        old_password: data.oldPassword,
        new_password: data.newPassword,
      });
      reset();
      toast({
        title: `✅ ${lang.get('msg.passwordUpdated')}`,
        description: lang.get('msg.passwordUpdatedDescription'),
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status === EStatusCode.OLD_PASSWORD_NOT_MATCH) {
        setChangePasswordError(lang.get('msg.oldPasswordIncorrect'));
      }
    }
  };
  return (
    <>
      <h2 className="mt-10 text-lg font-semibold">{lang.get('msg.changePassword')}</h2>
      <p style={{ color: currentTheme?.['secondary-text'] }} className="mt-1 mb-6 text-sm">
        {lang.get('msg.changePasswordSubtitle')}
      </p>
      <hr />
      <form className="max-w-lg" onSubmit={handleSubmit(onPasswordSubmit)}>
        <label
          style={{ color: currentTheme?.['primary-text'] }}
          className="flex flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative mt-6"
        >
          {lang.get('msg.oldPassword')}
          <Input {...register('oldPassword')} hasError={!!errors.oldPassword} type="password" />
          <p style={{ color: currentTheme?.['secondary-text'] }} className="text-xs">
            {lang.get('msg.oldPasswordSubtitle')}
          </p>
          {errors.oldPassword?.message && (
            <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
              {lang.get(`msg.${errors.oldPassword?.message}`)}
            </p>
          )}
        </label>
        <label
          style={{ color: currentTheme?.['primary-text'] }}
          className="flex  flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative mt-6"
        >
          {lang.get('msg.newPassword')}
          <Input {...register('newPassword')} hasError={!!errors.newPassword} type="password" />
          <p style={{ color: currentTheme?.['secondary-text'] }} className="text-xs">
            {lang.get('msg.newPasswordSubtitle')}
          </p>
          {errors.newPassword?.message && (
            <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
              {lang.get(`msg.${errors.newPassword?.message}`)}
            </p>
          )}
        </label>
        <label
          style={{ color: currentTheme?.['primary-text'] }}
          className="flex flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative mt-6"
        >
          {lang.get('msg.confirmPassword')}
          <Input
            {...register('confirmNewPassword')}
            hasError={!!errors.confirmNewPassword}
            type="password"
          />
          <p style={{ color: currentTheme?.['secondary-text'] }} className="text-xs">
            {lang.get('msg.confirmPasswordSubtitle')}
          </p>
          {errors.confirmNewPassword?.message && (
            <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
              {lang.get(`msg.${errors.confirmNewPassword?.message}`)}
            </p>
          )}
          {changePasswordError && (
            <p className="absolute right-0 ml-1 text-sm text-red-600 -bottom-4">
              {changePasswordError}
            </p>
          )}
        </label>
        <Button variant="default" disabled={isSubmitting} className="mt-6">
          {lang.get('msg.updatePassword')}
        </Button>
      </form>
    </>
  );
}

export default PasswordEditForm;
