import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { IconLoader2 } from '@tabler/icons-react';
import useTranslation from '@/hooks/useTranslation';
import QuestionItem from '@/components/QuestionItem/QuestionItem';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import useReportStore from '@/store/ReportStore';
import useCategoryStore from '@/store/CategoryStore';
import FullPageLoader from '@/components/atoms/Loader/FullPageLoader';
import useQuestionsLoadingStore from '@/store/QuestionsLoadingStore';
import { cn } from '@/lib/utils';
import { ProjectProps } from '@/types';

interface IAllQuestions {
  id: string;
  question: string;
}

interface IPerQuestion {
  isPendingReRun: boolean;
  analysisLanguage: string;
  singleProjectData: ProjectProps;
  newConversationsCount: number;
  onReRun: () => void;
  isRerunLoading: boolean;
}

function Questions({ isPendingReRun, analysisLanguage, singleProjectData, newConversationsCount, onReRun, isRerunLoading }: IPerQuestion) {
  const { lang } = useTranslation();
  const { project_id } = useParams();
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const { currentTheme } = useTheme();
  const categoryType = useCategoryStore((state) => state.categoryType);
  const questionsLoading = useQuestionsLoadingStore((state) => state.questionsLoading);
  const setQuestionsLoading = useQuestionsLoadingStore((state) => state.setQuestionsLoading);

  const [selectedQuestion, setSelectedQuestion] = useState<string | undefined>('');
  const setIsQuestionDataSelected = useReportStore((state) => state.setIsQuestionDataSelected);

  const { data: allQuestionsTitleAndID, isLoading: isAllQuestionsLoading, isFetched: isAllQuestionsFetched } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/results/${project_id}/perquestion/questions${filter_id ? `?filter_id=${filter_id}` : ''}`),
    queryKey: ['get_all_questions', { filter_id }],
    select: (data) => data?.data,
  });

  const { data: selectedQuestionInshights, isLoading: isLoadingInshights, error: errorInshights } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/results/${project_id}/perquestion/${selectedQuestion}/insights${filter_id ? `?filter_id=${filter_id}` : ''}`),
    queryKey: ['question_insights', { selectedQuestion, filter_id }],
    select: (data) => data?.data,
    enabled: !!selectedQuestion,
  });

  const { data: selectedQuestionTranscripts, error: errorTranscripts, isLoading: isTranscriptsLoading } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/results/${project_id}/perquestion/${selectedQuestion}/transcripts?category_type=${categoryType}
      ${filter_id ? `&filter_id=${filter_id}` : ''}`),
    queryKey: ['question_transcripts', { selectedQuestion, filter_id, categoryType }],
    select: (data) => data?.data,
    enabled: !!selectedQuestion && categoryType !== undefined,
  });

  const onQuestionChange = (e: string) => {
    setSelectedQuestion(e);
    setIsQuestionDataSelected(false);
  };

  useEffect(() => {
    setSelectedQuestion(allQuestionsTitleAndID?.[0]?.id ?? '');
  }, [allQuestionsTitleAndID]);

  useEffect(() => {
    setQuestionsLoading(isAllQuestionsLoading && !isAllQuestionsFetched);
  }, [isAllQuestionsFetched, isAllQuestionsLoading, setQuestionsLoading]);

  if (errorInshights || errorTranscripts) return null;
  if (isTranscriptsLoading && isLoadingInshights) return <FullPageLoader />;

  return (
    <div className="w-full mx-auto">
      <Select value={selectedQuestion} onValueChange={onQuestionChange}>
        <SelectTrigger
          style={{ borderColor: currentTheme?.primary }}
          className={cn(
            'flex h-fit w-full max-w-[856px] text-dark-text justify-between px-4 mb-2 font-semibold border-2 rounded-lg shadow-md border-primary',
            questionsLoading ? ' justify-center items-center' : 'self-start text-start',
          )}
        >
          {
            questionsLoading
              ? <IconLoader2 style={{ color: currentTheme?.primary }} size={18} className="mx-auto animate-spin" />
              : (
                <SelectValue
                  placeholder="Select a question"
                />
              )
          }
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>
              {!questionsLoading ? 'Select a question'
                : <IconLoader2 style={{ color: currentTheme?.primary }} size={18} className="mx-auto animate-spin" />}
            </SelectLabel>
            {
              allQuestionsTitleAndID?.map((item: IAllQuestions, index: number) => (
                <SelectItem key={item?.id} value={item?.id} className="cursor-pointer flex items-center max-w-[845px]">
                  <div className="flex items-center gap-4">
                    <span className="whitespace-nowrap">
                      {lang.get('msg.question')}
                      {' '}
                      {index + 1}
                      :
                    </span>
                    <span className="max-w-[700px] flex items-center py-0.5">
                      {item.question}
                    </span>
                  </div>
                </SelectItem>
              ))
            }
          </SelectGroup>
        </SelectContent>
      </Select>

      <div className="mt-10 mb-4">
        <QuestionItem
          isPendingReRun={isPendingReRun}
          selectedQuestionInshights={selectedQuestionInshights}
          isLoadingInshights={isLoadingInshights}
          selectedQuestionTranscripts={selectedQuestionTranscripts}
          questionID={selectedQuestion}
          analysisLanguage={analysisLanguage}
          singleProjectData={singleProjectData}
          newConversationsCount={newConversationsCount}
          onReRun={onReRun}
          isTranscriptsLoading={isTranscriptsLoading}
          isRerunLoading={isRerunLoading}
        />
      </div>

    </div>

  );
}

export default Questions;
