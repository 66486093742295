import { useContext, useEffect, useRef, useState } from 'react';
import { useBlocker, useLocation, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import * as Yup from 'yup';
import Cookie from 'js-cookie';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IconLoader2,
  IconSquareRoundedNumber1Filled,
  IconSquareRoundedNumber2Filled,
  IconSquareRoundedNumber3Filled,
  IconDeviceFloppy,
  IconCircleCheck,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import useChatStore from 'chat-ui/src/store';
import useChatSettingsStore from 'chat-ui/src/ChatSettingsStore';
import { ToastContainer } from 'react-toastify';
import { EProjectDesignSection, EProjectStatus, EStatusCode } from '@/enums';
import axiosInstance from '@/axios/axiosInstance';
import DashboardTestChat from '@/components/chat/DashboardTestChat';
import Context from '@/components/projects/single-project/research-design/Context';
import Instructions from '@/components/projects/single-project/research-design/Instructions';
import DiscussionGuide from '@/components/projects/single-project/research-design/DiscussionGuide';
import { Skeleton } from '@/components/ui/skeleton';
import { toast } from '@/components/ui/use-toast';
import { LangContext } from '@/context/LangProvider';
import useErrorStore from '@/store/ErrorStore';
import useQuestionsStore from '@/store/ScriptQuestionsStore';
import TestChatSkeletonLoading from './DashboardTestChatSkeletonLoading';
import { useMutateProjectUpdate } from '@/reactQuery/post';
import { Button } from '@/components/ui/button';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { testChatRegister } from '@/components/chat/axios/axiosInstanceChat';
import useCreateProjectStore from '@/store/CreateProjectStore';
import UnsavedChangesModal from '@/components/Modals/UnsavedChangesModal';
import {
  ProjectCreationValues,
  PublishedStateEditableFields,
  QuestionBoxProps,
  QuestionsFilesData,
  TestChatRegisterData,
} from '@/types';

const validationSchema = Yup.object().shape({
  type_of_interview: Yup.array().of(Yup.string()),
  interviewer_name: Yup.string(),
  company_name: Yup.string(),
  interview_goal: Yup.string(),
  interview_audience: Yup.string(),
  tone_of_voice: Yup.string(),
  privacy_policy: Yup.string().url('Please enter a valid URL'),
  language: Yup.string(),
  analysis_language: Yup.string(),
  allow_language_selection: Yup.boolean().nullable(),
  theme_id: Yup.string(),
  snippets: Yup.array(),
  opening_message: Yup.string(),
  interview_questions: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      question: Yup.string(),
      media: Yup.mixed(),
      follow_up_question: Yup.string(),
      answer_quantification: Yup.array().of(
        Yup.object().shape({
          quantify_answer: Yup.boolean(),
          value: Yup.string(),
        }),
      ),
    }),
  ),
});

function ProjectCreate() {
  const { lang } = useContext(LangContext);
  const { currentTheme } = useTheme();
  const { pathname } = useLocation();
  const { workspace_id, project_id } = useParams();
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const { questionsLength } = useQuestionsStore();
  const { setShowError } = useErrorStore();
  const {
    isFormChanged,
    setIsFormChanged,
    isMediaChanged,
    setIsMediaChanged,
    isMediaUploading,
    setIsMediaUploading,
    reorderChanged,
    setReorderChanged,
  } = useCreateProjectStore();
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(true);
  const [isContextOpen, setIsContextOpen] = useState(false);
  const [isDiscussionGuideOpen, setIsDiscussionGuideOpen] = useState(false);
  const { setInitialMessages, setCurrentProjectToken } = useChatStore();
  const [fileData, setFileData] = useState<{ [key: string]: QuestionsFilesData }>({});
  const { setChatSettingsData } = useChatSettingsStore();

  const {
    data: singleProjectData,
    error: projectDataError,
    refetch,
    isLoading: isProjectDataLoading,
  } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (data) => data?.data,
    retry: 0,
  });

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isFormChanged && currentLocation.pathname !== nextLocation.pathname,
  );

  const handleToggleSection = (section: string) => {
    setIsInstructionsOpen(
      section === EProjectDesignSection.INSTRUCTIONS ? !isInstructionsOpen : false,
    );
    setIsContextOpen(section === EProjectDesignSection.CONTEXT ? !isContextOpen : false);
    setIsDiscussionGuideOpen(
      section === EProjectDesignSection.DISCUSSION_GUIDE ? !isDiscussionGuideOpen : false,
    );
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type_of_interview: singleProjectData?.type_of_interview || [],
      interviewer_name: singleProjectData?.interviewer_name || '',
      company_name: singleProjectData?.company_name || '',
      interview_goal: singleProjectData?.interview_goal || '',
      interview_audience: singleProjectData?.interview_audience || '',
      tone_of_voice: singleProjectData?.tone_of_voice || '',
      language: singleProjectData?.language || '',
      allow_language_selection: singleProjectData?.allow_language_selection || null,
      privacy_policy: singleProjectData?.privacy_policy || '',
      analysis_language:
        singleProjectData?.analysis_language !== ''
          ? singleProjectData?.analysis_language
          : 'English',
      theme_id: singleProjectData?.theme_id || '',
      snippets: singleProjectData?.snippets || [],
      opening_message: singleProjectData?.opening_message || '',
      interview_questions:
        singleProjectData?.interview_questions?.map((question: QuestionBoxProps) => ({
          ...question,
        })) || [],
    },

    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync, status } = useMutateProjectUpdate();

  const isProjectPublished = singleProjectData?.status === EProjectStatus.PUBLISHED;
  const isProjectCompleted = singleProjectData?.status === EProjectStatus.COMPLETED;
  const watchedValuesForPublishState = watch([
    'type_of_interview',
    'analysis_language',
    'privacy_policy',
    'theme_id',
  ]);

  const prevWatchedValues = useRef<PublishedStateEditableFields[]>([]);

  const handleChatRegister = async () => {
    try {
      try {
        const data: TestChatRegisterData = await testChatRegister(project_id);
        if (data?.token) {
          setCurrentProjectToken(data?.token);
        } else {
          setCurrentProjectToken('');
        }
      } catch {
        toast({
          description: lang.get('msg.errorPleaseTryAgain'),
          variant: 'destructive',
        });
      }
    } catch {
      toast({
        description: lang.get('msg.errorPleaseTryAgain'),
        variant: 'destructive',
      });
    }
  };

  const getPublishedStateFormData = () => {
    const fieldKeys = ['type_of_interview', 'analysis_language', 'privacy_policy', 'theme_id'];
    const changedFields = watchedValuesForPublishState.reduce(
      (acc: Record<string, PublishedStateEditableFields>, value, index) => {
        if (JSON.stringify(value) !== JSON.stringify(prevWatchedValues.current[index])) {
          acc[fieldKeys[index]] = value;
        }
        return acc;
      },
      {},
    );

    return changedFields;
  };

  const onSubmit: SubmitHandler<ProjectCreationValues> = async (data: ProjectCreationValues) => {
    if (isUserViewer || isProjectCompleted) return;

    const payload = isProjectPublished ? getPublishedStateFormData() : data;
    prevWatchedValues.current = [...watchedValuesForPublishState];

    try {
      await mutateAsync({
        _id: project_id,
        ...payload,
      });

      if (isMediaChanged) {
        const mediaUploadPromises = Object.keys(fileData).map(async (questionId) => {
          const questionMedia = fileData[questionId].file;
          if (questionMedia) {
            setIsMediaUploading(true);
            try {
              await axios.post(
                `${import.meta.env.VITE_API_BASE_URL}/projects/media/${project_id}/${questionId}`,
                { media: questionMedia },
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${Cookie.get('token')}`,
                  },
                },
              );
            } catch {
              toast({
                title: 'Unsupported media type',
                description: 'Please upload an image (jpg, jpeg, png, gif, webp) or a video (mp4).',
                variant: 'destructive',
              });
            } finally {
              setIsMediaUploading(false);
            }
          }
        });

        await Promise.all(mediaUploadPromises);
      }

      setChatSettingsData(data?.type_of_interview as string[]);
      localStorage.setItem(`chat_settings_${project_id}`, String(data?.type_of_interview));

      await refetch();
      handleChatRegister();

      setReorderChanged(false);
      setIsFormChanged(false);
      setIsMediaChanged(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status !== EStatusCode.BAD_REQUEST) {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  useEffect(() => {
    setValue('type_of_interview', singleProjectData?.type_of_interview);
    setValue('interviewer_name', singleProjectData?.interviewer_name);
    setValue('company_name', singleProjectData?.company_name);
    setValue('interview_goal', singleProjectData?.interview_goal);
    setValue('interview_audience', singleProjectData?.interview_audience);
    setValue('tone_of_voice', singleProjectData?.tone_of_voice);
    setValue('privacy_policy', singleProjectData?.privacy_policy);
    setValue('language', singleProjectData?.language);
    setValue('allow_language_selection', singleProjectData?.allow_language_selection);
    setValue(
      'analysis_language',
      singleProjectData?.analysis_language !== ''
        ? singleProjectData?.analysis_language
        : 'English',
    );
    setValue('theme_id', singleProjectData?.theme_id);
    setValue('snippets', singleProjectData?.snippets);
    setValue('opening_message', singleProjectData?.opening_message || '');
    setValue('interview_questions', singleProjectData?.interview_questions || []);
  }, [setValue, singleProjectData]);

  useEffect(() => {
    if (singleProjectData && prevWatchedValues?.current.length === 0) {
      prevWatchedValues.current = [
        singleProjectData?.type_of_interview || [],
        singleProjectData?.analysis_language || 'English',
        singleProjectData?.privacy_policy || '',
        singleProjectData?.theme_id || '',
      ];
    }
  }, [singleProjectData]);
  useEffect(() => {
    if (
      (projectDataError as AxiosError)?.status === EStatusCode.BAD_REQUEST ||
      (projectDataError as AxiosError)?.status === EStatusCode.PROJECT_NOT_FOUND
    ) {
      setShowError(true);
    }
  }, [projectDataError, setShowError]);

  useEffect(() => {
    const handlePageLeave = () => {
      setInitialMessages([]);
    };

    return () => {
      handlePageLeave();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (errors?.privacy_policy?.message) {
      if (!isInstructionsOpen) {
        handleToggleSection(EProjectDesignSection.INSTRUCTIONS);

        const scrollAfterToggle = () => {
          const scrollContainer = document.querySelector('#scrollContainer');
          if (scrollContainer) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }
        };

        window.requestAnimationFrame(() => {
          setTimeout(scrollAfterToggle, 400);
        });
        toast({ description: 'Privacy policy must be a valid link.', variant: 'destructive' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isInstructionsOpen]);

  if (blocker.state === 'blocked') return <UnsavedChangesModal blocker={blocker} />;

  return (
    <main className="flex  w-full h-full -mt-2.5 relative overflow-y-hidden max-h-[90vh]">
      <ToastContainer position="top-center" className="mt-10" closeOnClick />

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-1/2 h-screen z-5">
        <h2 className="fixed pb-2 mt-3 text-xl font-semibold top-14 epilogue-font">
          {lang.get('msg.design')}
        </h2>
        <div
          id="scrollContainer"
          className="z-10 fixed w-[48%] top-[105px] flex flex-col h-full gap-2 pr-4 overflow-y-auto pb-44"
        >
          <div className="flex flex-col w-full">
            {singleProjectData ? (
              <button
                type="button"
                className="flex items-center justify-start w-full gap-1.5 py-2 mb-4 border-b"
                onClick={() => handleToggleSection(EProjectDesignSection.INSTRUCTIONS)}
              >
                <IconSquareRoundedNumber1Filled
                  style={{ color: currentTheme?.primary }}
                  size={20}
                />
                <p>{lang.get('msg.instructions')}</p>
              </button>
            ) : (
              <div className="flex flex-col mt-2.5 mb-4">
                <div className="flex gap-1.5">
                  <Skeleton className="w-5 h-5 rounded-sm" />
                  <Skeleton className="h-5 rounded-sm w-28" />
                </div>
                <Skeleton className="w-full h-0.5 mt-2.5" />
              </div>
            )}
            {/* instructions */}
            <div
              className={`transition-all h-full ease-in-out duration-700 flex flex-col overflow-hidden -mt-1 ${
                isInstructionsOpen ? 'max-h-[1500px]' : 'max-h-0'
              }`}
            >
              <Instructions
                singleProjectData={singleProjectData}
                isProjectDataLoading={isProjectDataLoading}
                isProjectPublished={isProjectPublished}
                isProjectCompleted={isProjectCompleted}
                register={register}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            </div>
          </div>

          {/* context */}
          <div className="flex flex-col w-full">
            <button
              type="button"
              className="flex items-center justify-start w-full gap-1.5 py-2 mb-4 border-b"
              onClick={() => handleToggleSection(EProjectDesignSection.CONTEXT)}
            >
              <IconSquareRoundedNumber2Filled style={{ color: currentTheme?.primary }} size={20} />
              {lang.get('msg.context')}
            </button>
            <div
              className={`transition-all ease-in-out duration-700 flex flex-col overflow-hidden -mt-1 ${
                isContextOpen ? 'max-h-[800px]' : 'max-h-0'
              }`}
            >
              <Context
                workspaceID={workspace_id}
                isProjectPublished={singleProjectData?.status === EProjectStatus.PUBLISHED}
                isProjectCompleted={singleProjectData?.status === EProjectStatus.COMPLETED}
                register={register}
                control={control}
                watch={watch}
              />
            </div>
          </div>

          {/* discussion guide */}
          <div className="flex flex-col w-full">
            <button
              type="button"
              className="flex items-center justify-start w-full gap-1.5 py-2 mb-4 border-b"
              onClick={() => handleToggleSection(EProjectDesignSection.DISCUSSION_GUIDE)}
            >
              <IconSquareRoundedNumber3Filled style={{ color: currentTheme?.primary }} size={20} />
              {lang.get('msg.discussionGuide')}
            </button>
            <div
              style={{ maxHeight: isDiscussionGuideOpen ? `${1000 + questionsLength * 200}px` : 0 }}
              className="flex flex-col -mt-1 overflow-hidden transition-all duration-1000 ease-in-out"
            >
              <DiscussionGuide
                singleProjectData={singleProjectData}
                isProjectPublished={singleProjectData?.status === EProjectStatus.PUBLISHED}
                isProjectCompleted={singleProjectData?.status === EProjectStatus.COMPLETED}
                register={register}
                control={control}
                setValue={setValue}
                getValues={getValues}
                setFileData={setFileData}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                fileData={fileData}
              />
            </div>
          </div>

          <div
            style={{ backgroundColor: currentTheme?.background }}
            className="fixed bottom-0 w-1/2 h-[66px] px-4 z-100 left-0"
          >
            <Button
              type="submit"
              className="w-full mt-2"
              disabled={
                status === 'pending' ||
                isMediaUploading ||
                isUserViewer ||
                isProjectCompleted ||
                (status === 'idle' && !isFormChanged && !reorderChanged) ||
                (status === 'success' && isFormChanged === false && reorderChanged === false)
              }
            >
              {/* Icon rendering */}
              {status === 'pending' && <IconLoader2 className="mr-1.5 animate-spin" size={20} />}

              {!(isFormChanged || reorderChanged) && status === 'success' && (
                <IconCircleCheck className="mr-1.5" size={20} />
              )}

              {(isFormChanged || reorderChanged) &&
                status !== 'pending' &&
                status !== 'success' && <IconDeviceFloppy className="mr-1.5" size={20} />}

              {/* Label rendering */}
              {status === 'idle' && !isFormChanged && !reorderChanged && (
                <span>{lang.get('msg.noChanges')}</span>
              )}

              {status === 'success' && !isFormChanged && !reorderChanged && (
                <span>{lang.get('msg.allSaved')}</span>
              )}

              {(isFormChanged || reorderChanged) && <span>{lang.get('msg.saveChanges')}</span>}
            </Button>
          </div>
        </div>
      </form>

      {/* chat side */}
      <div className="fixed right-0 w-1/2 h-full">
        <h2 className="mt-3 ml-8 text-xl font-semibold capitalize epilogue-font">
          {lang.get('msg.testChat')}
        </h2>
        <div
          style={{ backgroundColor: currentTheme?.background }}
          className="w-[98%] h-screen pb-20 pl-3"
        >
          {!isProjectDataLoading ? (
            <DashboardTestChat
              projectId={project_id}
              chatHeight="min-h-[calc(100dvh-190px)] max-h-[calc(100dvh-190px)]"
              singleProjectData={singleProjectData}
            />
          ) : (
            <TestChatSkeletonLoading />
          )}
        </div>
      </div>
    </main>
  );
}

export default ProjectCreate;
