import useTheme from 'chat-ui/src/hooks/useTheme';
import PasswordEditForm from '@/components/forms/PasswordEditForm';
import ProfileEditForm from '@/components/forms/ProfileEditForm';

function MyProfile() {
  const { currentTheme } = useTheme();

  return (
    <div style={{ color: currentTheme?.['primary-text'] }} className="max-w-3xl mx-3 min450:mx-0">
      <ProfileEditForm />
      <PasswordEditForm />
    </div>
  );
}

export default MyProfile;
