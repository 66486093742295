import * as React from 'react';

import { cn } from '@/lib/utils';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          'rounded-md border border-natural-700 transition-all flex h-10 w-full shadow-sm text-gray-700 focus-within:text-gray-900  px-3.5 py-2.5 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 focus-visible:outline-none focus-visible:shadow-fc-secondary disabled:pointer-events-none disabled:bg-gray-200 disabled:text-gray-400 invalid:shadow-hv-error invalid:bg-white invalid:text-gray-900 hover:border-gray-400 disabled:hover:border-transparent',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = 'Textarea';

export { Textarea };
