import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useQueryClient } from '@tanstack/react-query';
import useAuthStore from '@/store/AuthStore';
import useResultTabStore from '@/store/ResultsTabStore';

const SignOutUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setAuthToken } = useAuthStore();
  const { resetTabs } = useResultTabStore();

  const clearCache = () => queryClient.clear();

  const clearCookies = () => {
    Object.keys(Cookies.get()).forEach((cookieName) => Cookies.remove(cookieName));
  };

  const signOut = () => {
    setAuthToken(null);
    clearCookies();
    clearCache();
    localStorage.clear();
    navigate('/login');
    resetTabs();
  };

  return signOut;
};

export default SignOutUser;
