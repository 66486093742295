import { useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { IconChevronDown } from '@tabler/icons-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import Routes from '@/router/routes';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { getInitials } from '@/utils/stringHelpers';
import { LangContext } from '@/context/LangProvider';
import SignOutUser from '@/utils/signOutUser';
import useResultTabStore from '@/store/ResultsTabStore';
import { UserProps } from '@/types';

function UserIconDropDown({ user }: UserProps) {
  const { lang } = useContext(LangContext);
  const { currentTheme } = useTheme();
  const { organization_id } = useParams();
  const { resetTabs } = useResultTabStore();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="flex items-center justify-center">
        <div
          className="flex items-center gap-2 px-3 py-[4px] rounded-md"
          style={{ backgroundColor: currentTheme?.primary }}
        >
          <IconChevronDown size={17} className="mt-px text-white" />
          <div className="border-none rounded-full outline-none select-none max-h-8 max-w-8 ring-offset-transparent ring-transparent focus-visible:ring-0 focus-visible:shadow-none focus-visible:ring-opacity-0">
            <div className="flex items-center justify-center uppercase rounded-full min-w-[25px] min-h-[25px]">
              <Avatar className="object-cover w-full h-full">
                {/* add img later */}
                <AvatarImage src="" className="object-cover max-w-[26px] max-h-[26px]" />
                <AvatarFallback
                  style={{ color: currentTheme?.primary }}
                  className="h-[26px] w-[26px] font-semibold py-0.5 px-1 bg-white"
                >
                  {getInitials(user?.name || '')}
                </AvatarFallback>
              </Avatar>
            </div>
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="relative mr-4 z-100 w-52">
        <DropdownMenuLabel>
          <div className="flex items-center w-full gap-1.5">
            <Avatar className="object-cover max-w-[30px] max-h-[30px]">
              {/* add img later */}
              <AvatarImage src="" className="object-cover max-w-[30px] max-h-[30px]" />
              <AvatarFallback
                style={{ backgroundColor: currentTheme?.primary }}
                className="w-full h-full font-semibold py-0.5 px-1  text-white"
              >
                {getInitials(user?.name || '')}
              </AvatarFallback>
            </Avatar>
            <div className="flex flex-col">
              <p className="text-sm">{user?.name}</p>
              <p className="text-xs text-light-gray max-w-[140px] truncate">{user?.email}</p>
            </div>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuLabel className="text-[13px]">{lang.get('msg.myAccount')}</DropdownMenuLabel>
        <DropdownMenuGroup>
          <DropdownMenuItem className="h-6 p-0 pl-2 cursor-pointer">
            <NavLink
              onClick={resetTabs}
              className="w-full"
              to={`${Routes.organization.path}/${organization_id}${Routes.manage_account.path}`}
            >
              {lang.get('msg.manageAccount')}
            </NavLink>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={SignOutUser()}
          className="text-red-500 cursor-pointer hover:!text-red-600"
        >
          {lang.get('msg.logout')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default UserIconDropDown;
