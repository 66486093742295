import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TeletLogo from 'src/assets/logo/tellet-logo-main.svg';
import Image from '@/components/atoms/Image';
import Routes from '@/router/routes';
import axiosInstanceNoAuth from '@/axios/axiosInstanceNoAuth';
import { EStatusCode } from '@/enums';
import VerifyEmailModalScreen from './verify-email-modal-screen/VerifyEmailModalScreen';

function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const tokenURL = searchParams.get('token');
  const [isVerified, setIsVerified] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);
  const navigate = useNavigate();

  const errorStatusHandler: Record<number, () => void> = {
    [EStatusCode.INVALID_CREDENTIALS]: () => {
      setServerError(false);
      setInvalidLink(true);
      setIsVerified(false);
    },
    [EStatusCode.TOKEN_EXPIRED]: () => {
      setServerError(false);
      navigate(Routes.verification_email_link_expired.path);
    },
    [EStatusCode.ALREADY_VERIFIED]: () => {
      setServerError(false);
      setAlreadyVerified(true);
    },
  };

  // eslint-disable-next-line consistent-return
  const verifyToken = async () => {
    try {
      const response = await axiosInstanceNoAuth.post('/users/verify_email', undefined, {
        headers: {
          Authorization: `Bearer ${tokenURL}`,
        },
      });

      if (response.status === EStatusCode.CREATED) {
        setIsVerified(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      const statusCode = axiosError?.status;
      if (!statusCode) {
        setServerError(true);
        return;
      }
      (errorStatusHandler[statusCode] || (() => setServerError(true)))();
    }
  };

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isVerifiedScreen = isVerified && !serverError && !alreadyVerified;
  const isAlreadyVerifiedScreen = !isVerified && !serverError && alreadyVerified && !invalidLink;
  const isInvalidLinkScreen = !isVerified && !serverError && invalidLink;

  return (
    <main className="flex items-center justify-center w-full h-full min-h-screen text-center bg-light-pink">
      <div className="w-full max-w-[600px] min-h-screen center border-0 px-2 py-4 min450:bg-white min450:max-w-full">
        <div className="flex flex-col items-center w-full max-w-[600px] rounded-md h-fit p-7 min450:p-5">
          <div
            className="flex flex-col items-center min-h-[350px] w-full ring-1 ring-neutral-200 max-w-[500px]
           min450:ring-transparent min450:shadow-none gap-10 bg-white rounded-md shadow-md h-fit p-7 min450:p-2"
          >
            <Image effect="blur" src={TeletLogo} alt="logo" width={115} height={37} />

            {isVerifiedScreen && (
              <VerifyEmailModalScreen title="Successfully verified" linkText="Enter dashboard">
                Your email has been verified successfully.
                <br />
                You can enter the dashboard.
              </VerifyEmailModalScreen>
            )}
            {isAlreadyVerifiedScreen && (
              <VerifyEmailModalScreen title="Email already verified">
                Your email is already verified.
              </VerifyEmailModalScreen>
            )}
            {isInvalidLinkScreen && (
              <VerifyEmailModalScreen title="Invitation expired or invalid link">
                Login again to get a new invitation email.
              </VerifyEmailModalScreen>
            )}
            {serverError && (
              <VerifyEmailModalScreen title="Error occurred">
                An error occurred while verifying your email.
                <br />
                Please try again later.
              </VerifyEmailModalScreen>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default VerifyEmail;
