import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  IconLoader2,
  IconArrowsDownUp,
  IconArrowUp,
  IconArrowDown,
  IconChevronDown,
  IconChevronRight,
  IconPercentage,
  IconNumbers,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import { CategoryDisplayType, CategorySortType, ECategoryMode } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import { translateCategories } from '@/pages/projects/single-project/Content/project_results/resultsPageTranslations';
import { useMutateGenerateAiCategories } from '@/reactQuery/post';
import useCategoryStore from '@/store/CategoryStore';
import useQuestionsLoadingStore from '@/store/QuestionsLoadingStore';
import getTextColor from '@/utils/getTextColorContrast';
import CogIcon from '../icons/CogIcon';
import SparklesIcon from '../icons/SparklesIcon';
import EditCategoriesModal from '../Modals/EditCategoriesModal';
import { Button } from '../ui/button';
import { Skeleton } from '../ui/skeleton';
import { toast } from '../ui/use-toast';
import useCategoriesLoadingStore from '@/store/CategoriesLoadingStore';
import { cn } from '@/lib/utils';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import { Category, CategoryDisplay, CategoriesProps, SortOrder } from '@/types';
import { resolveTranslation } from '@/utils/resolveTranslation';

function Categories({ projectID, questionID, analysisLanguage }: CategoriesProps) {
  const { lang } = useTranslation();
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const { currentTheme } = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const { categoryType, setCategoryType } = useCategoryStore();
  const { questionsLoading } = useQuestionsLoadingStore();
  const { setCategoriesLoading } = useCategoriesLoadingStore();
  const [showCategoryDescription, setShowCategoryDescription] = useState<number | null>(null);
  const [sortCategory, setSortCategory] = useState<SortOrder>(CategorySortType.DEFAULT);
  const [categoryDisplay, setCategoryDisplay] = useState<CategoryDisplay>(
    (localStorage.getItem('category_display') as CategoryDisplay) || CategoryDisplayType.TALLY,
  );

  const { mutateAsync: mutateAsyncGenerateAICategories, status: generatingAICategoriesStatus } =
    useMutateGenerateAiCategories();

  const {
    data: categoryData,
    isLoading,
    isFetching,
  } = useQuery({
    queryFn: () => {
      const baseUrl = `/analyzer/quantification/${projectID}/${questionID}`;
      const url = filter_id ? `${baseUrl}?filter_id=${filter_id}` : baseUrl;
      return axiosInstanceAnalyze.get(url);
    },
    queryKey: ['category_data', { questionID, filter_id }],
    enabled: !!projectID && !!questionID,
    select: (category_data) => category_data?.data,
  });

  const manualyCreateCategoriesModal = () => {
    setOpenModal(true);
    setCategoryType(ECategoryMode.SINGLE);
  };

  const handleShowCategoryDescription = (index: number) => {
    setShowCategoryDescription((prev) => (prev === index ? null : index));
  };

  const generateAICategories = async () => {
    try {
      await mutateAsyncGenerateAICategories({
        projectID,
        questionID,
      });
      setOpenModal(true);
    } catch (err) {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const calculatePercentages = (data: Category[], convNumber: number) => {
    if (!data) return null;
    return data.map((cat: Category) => ({
      ...cat,
      percentage: (cat.count / convNumber) * 100,
    }));
  };

  const getDataWithPercentagesField = useCallback(calculatePercentages, []);

  const conversationsNumber = categoryData?.conversations_num;
  const doesCategoryDataExists =
    categoryData?.categories?.length !== 0 || categoryData?.categories_multi?.length !== 0;

  const categoriesPicked = useMemo(() => {
    const categories =
      categoryType === ECategoryMode.SINGLE
        ? categoryData?.categories
        : categoryData?.categories_multi;
    return getDataWithPercentagesField(categories, conversationsNumber);
  }, [categoryData, categoryType, conversationsNumber, getDataWithPercentagesField]);

  const [sortedCategories, setSortedCategories] = useState<Category[]>(categoriesPicked || []);

  const handleSortCategory = useCallback(() => {
    if (!categoriesPicked) return;

    const sortedData = [...categoriesPicked];

    const sortActions: Record<CategorySortType, () => void> = {
      [CategorySortType.DEFAULT]: () => {
        sortedData.sort((a, b) => a.count - b.count);
        setSortCategory(CategorySortType.ASC);
      },
      [CategorySortType.ASC]: () => {
        sortedData.sort((a, b) => b.count - a.count);
        setSortCategory(CategorySortType.DESC);
      },
      [CategorySortType.DESC]: () => {
        setSortCategory(CategorySortType.DEFAULT);
      },
    };

    sortActions[sortCategory]?.();
    setSortedCategories(sortedData);
  }, [categoriesPicked, sortCategory]);

  const handleDisplay = () => {
    setCategoryDisplay((prev) => (prev === CategoryDisplayType.TALLY ? 'percentage' : 'tally'));
    localStorage.setItem(
      'category_display',
      categoryDisplay === CategoryDisplayType.TALLY ? 'percentage' : 'tally',
    );
  };

  const getSortIcon = () => {
    if (sortCategory === CategorySortType.DEFAULT) return <IconArrowsDownUp />;
    if (sortCategory === CategorySortType.ASC) return <IconArrowUp />;
    if (sortCategory === CategorySortType.DESC) return <IconArrowDown />;
    return null;
  };

  const getPresentationIcon = () => {
    if (categoryDisplay === CategoryDisplayType.PERCENTAGE) return <IconNumbers />;
    if (categoryDisplay === CategoryDisplayType.TALLY) return <IconPercentage />;
    return null;
  };

  const maxCount =
    categoriesPicked && categoriesPicked.length > 0
      ? Math.max(...categoriesPicked.map((cat: { count: number }) => cat.count)) || 1
      : 1;

  useEffect(() => {
    setCategoriesLoading(isLoading && isFetching);
  }, [setCategoriesLoading, isLoading, isFetching]);

  useEffect(() => {
    setCategoryType(categoryData?.last_ran);
  }, [categoryData, setCategoryType]);

  useEffect(() => {
    if (categoriesPicked) {
      setSortedCategories(categoriesPicked);
    }
  }, [categoriesPicked]);

  const shouldDisplayCategories = doesCategoryDataExists && !questionsLoading;
  const shouldDisplayModal = !questionsLoading && !isFetching && categoriesPicked;
  const shouldDisplaySkeleton = isLoading || isFetching || questionsLoading || !sortedCategories;
  const isPending = generatingAICategoriesStatus === 'pending';
  const isTally = categoryDisplay === CategoryDisplayType.TALLY;

  return (
    <div className="flex flex-col">
      {/* edit categories */}
      <div className="flex items-center justify-between w-full mb-3">
        <div className="flex flex-col">
          <h3
            style={{ color: currentTheme?.primary }}
            className="!mb-1 text-lg font-bold epilogue-font"
          >
            {resolveTranslation(translateCategories, analysisLanguage)}
          </h3>
          {shouldDisplayCategories && (
            <p>
              Category mode:
              <b style={{ color: currentTheme?.primary }} className="ml-1 capitalize">
                {categoryData?.last_ran}
              </b>
            </p>
          )}
        </div>

        {shouldDisplayModal && (
          <EditCategoriesModal
            data={categoriesPicked}
            project_id={projectID}
            question_id={questionID}
            setOpenModal={setOpenModal}
            openModal={openModal}
            isCategoryDataExists={doesCategoryDataExists}
            conversationsNumber={conversationsNumber}
            isLoading={isLoading && isFetching}
          />
        )}
      </div>

      {/*  eslint-disable-next-line no-nested-ternary */}
      {shouldDisplaySkeleton ? (
        <div
          style={{ borderColor: currentTheme?.['secondary-text'] }}
          className="flex w-full p-8 border rounded-lg"
        >
          <div className="flex w-full max1100:flex-col max1100:gap-6 max1100:items-center max1100:justify-center">
            {/* skeleton loaders */}
            <div
              style={{ borderColor: currentTheme?.['secondary-text'] }}
              className="flex w-[400px] max1100:w-full max1100:pr-0 items-start border-r pr-3 max1100:border-none"
            >
              <div className="flex flex-col gap-3.5 w-full">
                {[1, 2, 3]?.map((item: number) => (
                  <div key={item} className="flex items-start gap-3">
                    <div className="flex flex-col w-full gap-2">
                      <Skeleton className="w-full h-4 rounded-sm max-w-[50%]" />
                      <Skeleton className="w-full h-3 rounded-sm" />
                      <Skeleton className="w-full h-3 rounded-sm max-w-[90%]" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="items-center justify-center w-full max1100:flex max1100:flex-col">
              <div className="flex justify-end w-full h-full">
                <div className="w-full h-full max1100:flex max1100:mt-5 max1100:items-center max1100:justify-center">
                  <div className="flex flex-col justify-between w-full h-full min-h-full pr-4 ml-12 max1100:max-w-full max1100:gap-4 max1100:ml-0 border-black/30">
                    {[1, 2, 3, 4]?.map((item: number) => (
                      <div key={item} className="flex items-start gap-3">
                        <Skeleton className="w-[90%] rounded-sm h-7" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : !doesCategoryDataExists ? (
        // buttons to choose category if no category data exists
        <div className="flex items-center gap-4 mx-auto">
          <div className="flex items-center justify-center gap-6">
            <Button disabled={isPending} onClick={generateAICategories} variant="default">
              {isPending ? (
                <div className="flex items-center gap-1">
                  <IconLoader2 className="size-5 animate-spin" />
                  {lang.get('msg.generatingCategories')}
                  ...
                </div>
              ) : (
                <div className="flex items-center gap-1">
                  <SparklesIcon className="size-5" />
                  {lang.get('msg.aiSuggestedCategories')}
                </div>
              )}
            </Button>
          </div>

          <div className="flex items-center justify-center gap-6">
            <Button disabled={isPending} onClick={manualyCreateCategoriesModal} variant="default">
              <CogIcon className="mr-1 size-5" />
              {lang.get('msg.createMyOwnCategories')}
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{ borderColor: currentTheme?.['secondary-text'] }}
          className="flex w-full p-8 border rounded-lg"
        >
          <div className="flex w-full max1100:flex-col max1100:gap-6 max1100:items-center max1100:justify-center">
            {/* labels */}
            <div
              style={{ borderColor: currentTheme?.['secondary-text'] }}
              className="flex w-[400px] max1100:w-full max1100:pr-0 items-start border-r pr-3 max1100:border-none"
            >
              <div className="flex flex-col w-full gap-4">
                {sortedCategories?.map((item: Category, index: number) => (
                  <button
                    type="button"
                    onClick={() => handleShowCategoryDescription(index)}
                    key={item?.id}
                    className="flex text-left items-start gap-3 text-[15px]"
                  >
                    <span
                      style={{
                        backgroundColor: `${item?.count > 0 && item?.color}`,
                        color: getTextColor(
                          item?.count > 0 ? item?.color : (currentTheme?.background as string),
                        ),
                      }}
                      className="flex items-center justify-center w-5 h-5 mt-0.5 font-semibold rounded-md"
                    >
                      {index + 1}
                    </span>
                    <div className="flex flex-col w-full">
                      <span className="flex items-center justify-between w-full font-medium">
                        {item?.label}
                        <p className="self-end whitespace-nowrap">
                          {showCategoryDescription === index ? (
                            <IconChevronDown size={20} />
                          ) : (
                            <IconChevronRight size={20} />
                          )}
                        </p>
                      </span>
                      <span className="mt-0.5 text-xs flex items-center">
                        <p className={cn(showCategoryDescription !== index && 'hidden')}>
                          {item?.prompt}
                        </p>
                      </span>
                    </div>
                  </button>
                ))}
              </div>
            </div>
            <div className="relative items-center justify-center w-full max1100:flex max1100:flex-col">
              {/* sorting button */}
              <TooltipWrapper text={`Sort: ${sortCategory}`} className="mb-1 -ml-3">
                <Button
                  onClick={handleSortCategory}
                  variant="outline"
                  type="button"
                  className="absolute px-3 -right-6 -top-6"
                >
                  {getSortIcon()}
                </Button>
              </TooltipWrapper>

              <TooltipWrapper
                text={`Display: ${!isTally ? 'tally' : 'percentage'}`}
                className="mb-1 -ml-3"
              >
                <Button
                  onClick={handleDisplay}
                  variant="outline"
                  type="button"
                  className="absolute px-3 -right-6 top-6"
                >
                  {getPresentationIcon()}
                </Button>
              </TooltipWrapper>

              <div className="flex justify-end w-full h-full">
                <div className="w-full h-full max1100:flex max1100:mt-5 max1100:items-center max1100:justify-center">
                  <div className="flex flex-col max-w-[90%] w-full gap-4 justify-between ml-12 max1100:ml-0 min-h-full h-full border-black/30 pr-4">
                    {sortedCategories?.map((item: Category) => {
                      const widthPercentage = (item.count / maxCount) * 100;
                      return (
                        <div
                          key={item?.id}
                          style={{
                            backgroundColor: `${item?.count > 0 && item.color}`,
                            width: `${widthPercentage}%`,
                            minWidth: isTally ? '1ch ' : '5.5ch',
                            color: getTextColor(
                              item?.count > 0 ? item?.color : (currentTheme?.background as string),
                            ),
                          }}
                          className="flex font-medium rounded-sm pl-7 max1100:mt-0 first:mt-0"
                        >
                          <p className="-ml-5">
                            {isTally ? item?.count : `${item?.percentage?.toFixed(2)}%`}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Categories;
