import { Controller } from 'react-hook-form';
import { Input } from '@/components/ui/input';
import { DefaultFormFieldProps } from '@/types';

function AgreeOnTermsField({ control, trigger, name }: DefaultFormFieldProps) {
  return (
    <div className="flex flex-row-reverse  items-center gap-1.5 whitespace-nowrap relative items-start w-fit h-fit mt-2">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <label className="text-sm select-none text-dark-text cursor-pointer" htmlFor={name}>
              I agree on the{' '}
              <a
                target="_blank"
                href="https://tellet.ai/terms-of-service"
                className="underline text-primary"
                rel="noreferrer"
              >
                Terms of Service
              </a>
            </label>
            <Input
              {...field}
              id={name}
              type="checkbox"
              className="cursor-pointer mt-0.5 h-fit"
              checked={field.value}
              onChange={(e) => {
                field.onChange(e);
                trigger('tos');
              }}
            />
            {error?.message && (
              <p className="absolute left-0 text-xs text-red-600 whitespace-nowrap -bottom-5 font-semibold">
                {error?.message}
              </p>
            )}
          </>
        )}
      />
    </div>
  );
}

export default AgreeOnTermsField;
