import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { toast } from '@/components/ui/use-toast';
import { Input } from '../ui/input';
import { LangContext } from '@/context/LangProvider';
import { Button } from '../ui/button';
import { useMutateDeleteWorkspace } from '@/reactQuery/post';
import { EStatusCode } from '@/enums';
import useGetAllWorkspaces from '@/hooks/useGetAllWorkspaces';
import Routes from '@/router/routes';
import { DeleteWorkspaceModalProps, RenameProps } from '@/types';

const defaultValues = {
  name: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Workspace name is required'),
});

function DeleteWorkspace({ currentWorkspaceName, currentWorkspaceId }: DeleteWorkspaceModalProps) {
  const { lang } = useContext(LangContext);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
  const navigate = useNavigate();
  const { organization_id } = useParams();
  const { firstWorkspaceInList } = useGetAllWorkspaces();

  const [openModal, setOpenModal] = useState(false);

  const { mutateAsync: mutateAsyncDeleteWorkspace } = useMutateDeleteWorkspace();

  const onSubmit: SubmitHandler<RenameProps> = async (data) => {
    try {
      if (data?.name === currentWorkspaceName) {
        await mutateAsyncDeleteWorkspace({ currentWorkspaceId });
        setOpenModal(false);
        reset();
        navigate(
          `/organization/${organization_id}${Routes.workspace.path}/${firstWorkspaceInList}`,
        );
        toast({ description: lang.get('msg.workspaceSuccessfullyDeleted') });
      } else {
        toast({ description: lang.get('msg.invalidWorkspaceName'), variant: 'destructive' });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status === EStatusCode.CANT_DELETE_WORKSPACE) {
        toast({ description: lang.get('msg.lastWorkspaceError'), variant: 'destructive' });
      } else if (axiosError?.status === EStatusCode?.CANT_DELETE_PUBLISHED) {
        toast({
          description: lang.get('msg.publishedOrPausedDeletingWorkspaceError'),
          variant: 'destructive',
        });
      } else {
        toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
      }
    }
  };

  return (
    <AlertDialog open={openModal}>
      <AlertDialogTrigger onClick={() => setOpenModal(true)} asChild>
        <p className="py-1 pl-2 text-sm text-red-600 cursor-pointer hover:bg-secondary-background">
          {lang.get('msg.delete')}
        </p>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex items-center gap-1">
              {lang.get('msg.deleteWorkspace')}
            </AlertDialogTitle>
            <p className="text-sm text-dark-text">
              {lang.get('msg.areYouSureYouWantToDeleteWorkspace')}
              <br />
              {lang.get('msg.deleteWorkspaceModalDescription')}
              <span className="font-bold"> {currentWorkspaceName} </span>
              {lang.get('msg.workspace')}
            </p>
            <div className="flex flex-col pt-4 pb-12">
              <label className="relative flex flex-col w-full gap-1">
                <p className="mb-2 text-sm text-dark-text">
                  {lang.get('msg.toProceedPleaseEnterWorkspaceName')}
                </p>
                <Input
                  {...register('name')}
                  placeholder={lang.get('msg.enterWorkspaceName')}
                  className={`w-full ${errors?.name?.message && 'border-red-600/50'}`}
                />
                <p
                  className={`text-xs md700:hidden font-medium text-red-600 opacity-0 absolute -bottom-5 ${
                    errors?.name?.message && 'opacity-100'
                  }`}
                >
                  {errors?.name?.message}
                </p>
              </label>
            </div>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setOpenModal(false)}>
              {lang.get('msg.cancel')}
            </AlertDialogCancel>
            <Button type="submit" className="bg-red-600 hover:bg-red-700" disabled={isSubmitting}>
              {lang.get('msg.submit')}
            </Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default DeleteWorkspace;
