import useTheme from 'chat-ui/src/hooks/useTheme';
import { IconLoader2 } from '@tabler/icons-react';
import { TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import { QuestionItemTabsProps } from '@/types';

function QuestionItemTabs({ tabs, handleTabFocus, tabStyle, lang }: QuestionItemTabsProps) {
  const { currentTheme } = useTheme();
  return (
    <TabsList className="w-fit rounded-xl dark:bg-neutral-800 bg-neutral-100 flex items-center justify-center">
      {tabs.map(({ value, disabled, isLoading, labelKey }) => (
        <TabsTrigger
          key={value}
          disabled={disabled}
          style={tabStyle(value)}
          onFocus={() => handleTabFocus(value)}
          className="rounded-lg py-2 w-[350px] md900:w-[250px] md700:w-[200px] md600:w-[150px] text-sm font-medium flex items-center justify-center"
          value={value}
        >
          {isLoading ? (
            <IconLoader2
              style={{ color: currentTheme?.primary }}
              className="animate-spin"
              size={18}
            />
          ) : (
            lang.get(labelKey)
          )}
        </TabsTrigger>
      ))}
    </TabsList>
  );
}

export default QuestionItemTabs;
