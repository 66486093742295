import clsx from 'clsx';
import useTheme from 'chat-ui/src/hooks/useTheme';
import SummarySkeletonLoader from '@/components/loaders/SummarySkeletonLoader';
import { ProjectSummaryProps } from '@/types';
import TextWithClickableQuotes from '@/utils/TextWithClickableQuotes';

function ProjectSummary({
  title,
  summary,
  isLoading,
  noSummarydata,
  marginBottom,
}: ProjectSummaryProps) {
  const { currentTheme } = useTheme();
  const hasSummary = !!summary?.length;

  return (
    <div className="flex flex-col w-full">
      <h3
        style={{ color: currentTheme?.primary }}
        className={clsx('text-lg font-bold epilogue-font', marginBottom)}
      >
        {title}
      </h3>
      {hasSummary ? (
        <div>
          {isLoading ? <SummarySkeletonLoader /> : <TextWithClickableQuotes text={summary || ''} />}
        </div>
      ) : (
        <p className="w-full text-left text-[15px]  mx-auto">{noSummarydata}</p>
      )}
    </div>
  );
}

export default ProjectSummary;
