import { ProjectQuestionInsightsProps } from '@/types';
import DataWithSteps from './DataWithSteps';
import QuestionItemTitle from './QuestionItemTitle';

function ProjectQuestionInsights({
  data,
  isLoading,
  title,
  noDataText,
}: ProjectQuestionInsightsProps) {
  return (
    <div className="flex flex-col w-full">
      <QuestionItemTitle>{title}</QuestionItemTitle>
      {data ? (
        <DataWithSteps data={data} isLoading={isLoading} />
      ) : (
        <p className="w-full text-left text-[15px] mx-auto">{noDataText}</p>
      )}
    </div>
  );
}

export default ProjectQuestionInsights;
