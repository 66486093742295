import { useEffect, useState } from 'react';
import TeletLogo from 'src/assets/logo/tellet-logo-main.svg';
import * as Yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { IconLoader2 } from '@tabler/icons-react';
import { Button } from '@/components/ui/button';
import Image from '@/components/atoms/Image';
import Routes from '@/router/routes';
import { toast } from '@/components/ui/use-toast';
import axiosInstanceNoAuth from '@/axios/axiosInstanceNoAuth';
import useAuthStore from '@/store/AuthStore';
import TelephoneField from '@/pages/auth/fields/TelephoneField';
import TextField from '@/pages/auth/fields/TextField';
import AgreeOnTermsField from '@/pages/auth/fields/AgreeOnTermsField';
import { SignupFormValues } from '@/types';

const defaultValues = {
  name: '',
  last_name: '',
  phone_number: '',
  email: '',
  password: '',
  confirm_password: '',
  tos: false,
  organization_name: '',
};

type SignUpForm = typeof defaultValues;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  organization_name: Yup.string().required('Organization name is required'),
  phone_number: Yup.string()
    .required('Phone number is required')
    .min(8, 'Invalid phone number')
    .max(20, 'Invalid phone number'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('This field is required'),
  tos: Yup.bool()
    .oneOf([true], 'Please accept the Terms of Service.')
    .required('Please accept the Terms of Service.'),
});

function SignUp() {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    trigger,
    clearErrors,
    formState: { isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema), mode: 'onBlur' });
  const navigate = useNavigate();
  const [signUpError, setSignUpError] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const authToken = useAuthStore((state) => state.authToken);
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const userName = watch('name');
  const organizationName = watch('organization_name');

  const stepOneFields = Object.keys(defaultValues).filter(
    (key) => key !== 'organization_name',
  ) as (keyof SignUpForm)[];
  const stepOneValues = watch(stepOneFields);
  const isStepOneComplete = stepOneValues.every((value) => value && value !== '');
  const isSubmitButtonDisabled = isSubmitting || !organizationName;

  useEffect(() => {
    if (authToken) {
      navigate(from, { replace: true });
    }
  }, [authToken, navigate, from]);

  useEffect(() => {
    if (userName) {
      setValue('organization_name', `${userName}'s organization`);
      clearErrors('organization_name');
    }
  }, [userName, clearErrors, setValue]);

  // eslint-disable-next-line consistent-return
  const onSubmit: SubmitHandler<SignupFormValues> = async (formData: SignUpForm) => {
    try {
      const email = formData?.email?.toLowerCase();
      const data = { ...formData, email };
      const response = await axiosInstanceNoAuth.post('/users/register', data);
      toast({
        title: 'Please verify your email',
        description: 'Please check your email to confirm registration.',
      });
      setSignUpError('');
      navigate(`${Routes.check_your_email.path}?email=${encodeURIComponent(response?.data)}`);
      return response;
    } catch (err) {
      setSignUpError('Provided email is already in use.');
    }
  };

  return (
    <main className="flex items-center justify-center w-full h-full min-h-screen text-center bg-light-pink">
      <div className="w-full max-w-[700px] min-h-screen center border-0 py-4 px-3 min450:max-w-full min450:bg-white">
        <div className="flex flex-col items-center w-full max-w-[700px] gap-10 bg-white rounded-md h-fit p-7 min450:p-5">
          <Image effect="blur" src={TeletLogo} alt="logo" width={120} height={40} />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="relative flex flex-col items-start w-full gap-5 h-fit"
          >
            {/* Step 1 - USER DETAILS */}
            {currentStep === 1 && (
              <div className="flex flex-col w-full gap-5">
                <div className="flex items-center w-full gap-4">
                  <TextField control={control} name="name">
                    First name
                  </TextField>
                  <TextField control={control} name="last_name">
                    Last name
                  </TextField>
                </div>
                <TelephoneField control={control} name="phone_number" />
                <TextField control={control} name="email">
                  Email
                </TextField>
                <div className="flex items-center w-full gap-4 mb-2">
                  <TextField
                    control={control}
                    name="password"
                    type="password"
                    errorMsgPosition="bottom"
                  >
                    Password
                  </TextField>
                  <TextField
                    control={control}
                    name="confirm_password"
                    type="password"
                    errorMsgPosition="bottom"
                  >
                    Confirm Password
                  </TextField>
                </div>
                <AgreeOnTermsField trigger={trigger} control={control} name="tos" />

                <Button
                  onClick={() => setCurrentStep(2)}
                  disabled={!isStepOneComplete}
                  variant="default"
                  type="button"
                  className="flex items-center gap-0.5 w-full mt-1.5 mb-1 text-sm font-semibold text-center ease-in rounded-md"
                >
                  {isSubmitting && <IconLoader2 className="w-4 h-4 mr-2 animate-spin" />}
                  Next
                </Button>
              </div>
            )}

            {/* Step 2 - ORGANIZATION NAME */}
            {currentStep === 2 && (
              <div className="flex flex-col justify-center w-full relative">
                <TextField control={control} required name="organization_name">
                  Organization name
                </TextField>
                <div className="flex gap-4 mt-4 w-full mx-auto">
                  <Button
                    variant="outline"
                    type="button"
                    className="flex items-center gap-0.5 w-1/2 text-sm font-semibold text-center ease-in rounded-md"
                    onClick={() => setCurrentStep(1)}
                  >
                    Back
                  </Button>
                  <Button
                    disabled={isSubmitButtonDisabled}
                    variant="default"
                    type="submit"
                    className="flex items-center gap-0.5 w-1/2 text-sm font-semibold text-center ease-in rounded-md"
                  >
                    {isSubmitting && <IconLoader2 className="w-4 h-4 mr-2 animate-spin" />}
                    Sign Up
                  </Button>
                </div>

                {signUpError && (
                  <p className="absolute text-sm font-medium text-red-600 left-0 -bottom-7">
                    {signUpError}
                  </p>
                )}
              </div>
            )}
          </form>
          <p className="text-sm">
            Already a member?
            {' '}
            <NavLink to="/login" className="font-semibold text-primary hover:underline">
              Login now.
            </NavLink>
          </p>
        </div>
      </div>
    </main>
  );
}

export default SignUp;
