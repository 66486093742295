import { useQuery } from '@tanstack/react-query';
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import useTheme from 'chat-ui/src/hooks/useTheme';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axiosInstance from '@/axios/axiosInstance';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { LangContext } from '@/context/LangProvider';
import { EProjectStatus } from '@/enums';
import useGetAllWorkspaces from '@/hooks/useGetAllWorkspaces';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { cn } from '@/lib/utils';
import Routes from '@/router/routes';
import { projectStatusColors, projectStatusLabel } from '@/utils/projectStatusLabelAndColors';
import WorkspaceProjectActionsDD from '../dropdowns/WorkspaceProjectActionsDD';
import Squares2X2Icon from '../icons/Squares2X2Icon';
import TableCellsIcon from '../icons/TableCellsIcon';
import CreateProjectModal from '../Modals/CreateProjectModal';
import { Skeleton } from '../ui/skeleton';
import MyWorkspaceColumns from '@/components/all-tellets-table/MyWorkspaceColumns';
import DataTablePagination from '../paginations/DataTablePagination';
import { GridData, ProjectsProps, TableProps } from '@/types';

export default function MyWorkspaceTable({ currentWorkspace }: TableProps) {
  const { mergedWorkspacesList } = useGetAllWorkspaces();
  const { isUserOwner, isUserEditor } = useWorkspaceRoles(currentWorkspace);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const { lang } = useContext(LangContext);
  const [filterGridData, setFilterGridData] = useState<string>('');
  const { pathname } = useLocation();
  const [tableLayout, setTableLayout] = useState(() => {
    const savedTableLayout = localStorage.getItem('tableLayout');
    return savedTableLayout || 'list';
  });
  const { currentTheme } = useTheme();

  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSkeleton(true);
    }, 150);

    return () => clearTimeout(timer);
  }, []);

  const {
    data: projectsData,
    isLoading,
    error,
  } = useQuery({
    queryFn: () => axiosInstance.post('projects/get_all', { workspace_id: currentWorkspace }),
    queryKey: ['all_projects_data', currentWorkspace],
    enabled: !!currentWorkspace,
  });

  const columns = MyWorkspaceColumns(
    lang,
    mergedWorkspacesList,
    isUserOwner,
    isUserEditor,
    pathname,
  );

  const changeLayoutTo = (newLayout: string) => {
    localStorage.setItem('tableLayout', newLayout);
    setTableLayout(newLayout);
  };

  const filteredGridData = projectsData?.data?.filter((item: ProjectsProps) =>
    item.title.toLowerCase().includes(filterGridData.toLowerCase()),
  );

  const removePreviousConversationFromStorage = (id: string) => {
    localStorage.removeItem(`current_conversation_id/test/${id}`);
  };

  const getStatusLabel = (status: EProjectStatus): string => projectStatusLabel[status];
  const getStatusColor = (status: EProjectStatus): string => projectStatusColors[status];

  const table = useReactTable({
    data: projectsData?.data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  if (isLoading && showSkeleton) {
    return (
      <div className="w-full mt-4">
        <div className="flex flex-wrap items-center justify-between w-full gap-4 pt-4 pb-2 md900:w-full md900:gap-2 md900:flex-col">
          <div className="flex items-center gap-2 mb-4 w-fit md900:w-full md900:flex-col">
            <Skeleton className="w-[185px] md900:w-full h-10 rounded-md" />
            <Skeleton className="w-[260px] md900:w-full h-10 rounded-md" />
          </div>
          <div className="flex items-center">
            <Skeleton className="w-[96px] opacity-50 h-10 rounded-l-md rounded-r-none mb-4" />
            <Skeleton className="w-[96px] opacity-100 h-10 rounded-r-md rounded-l-none mb-4" />
          </div>
        </div>
        <Skeleton className="w-full h-96" />
      </div>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!error && projectsData && (
        <div className="w-full mt-4">
          <div className="flex flex-wrap items-center justify-between w-full gap-4 pt-1 pb-2 md900:w-full md900:gap-2 md900:flex-col">
            {/* email filter and cols filtering */}
            <div className="flex items-center gap-2 mb-4 w-fit md900:w-full md900:flex-col">
              <CreateProjectModal currentWorkspace={currentWorkspace} />
              {/* table name filter */}
              {tableLayout === 'list' ? (
                <Input
                  placeholder={lang.get('msg.filterByProjectTitle')}
                  value={(table.getColumn('title')?.getFilterValue() as string) ?? ''}
                  onChange={(event) => table.getColumn('title')?.setFilterValue(event.target.value)}
                  className="w-64 md900:w-full"
                />
              ) : (
                <Input
                  placeholder={lang.get('msg.filterByProjectTitle')}
                  value={filterGridData}
                  onChange={(event) => setFilterGridData(event.target.value)}
                  className="w-64 md900:w-full"
                />
              )}
            </div>
            {/* layout btns */}
            <div className="flex items-center mb-4">
              <Button
                style={{
                  backgroundColor:
                    tableLayout === 'grid'
                      ? currentTheme?.primary
                      : currentTheme?.['lighter-primary'],
                  color: tableLayout === 'grid' ? '#FFFFFF' : currentTheme?.['primary-text'],
                }}
                onClick={() => changeLayoutTo('grid')}
                variant="secondary"
                className="flex items-center w-24 gap-1 rounded-r-none hover:text-white"
              >
                <Squares2X2Icon className="size-4" />
                {lang.get('msg.grid')}
              </Button>
              <Button
                style={{
                  backgroundColor:
                    tableLayout === 'list'
                      ? currentTheme?.primary
                      : currentTheme?.['lighter-primary'],
                  color: tableLayout === 'list' ? '#FFFFFF' : currentTheme?.['primary-text'],
                }}
                onClick={() => changeLayoutTo('list')}
                variant="secondary"
                className="flex items-center w-24 gap-1 rounded-l-none hover:text-white"
              >
                <TableCellsIcon className="size-5" />
                {lang.get('msg.list')}
              </Button>
            </div>
          </div>
          {/* -- table layout -- */}
          {tableLayout === 'list' ? (
            <>
              <div className="border rounded-md">
                <Table className="text-dark-text">
                  <TableHeader>
                    {table?.getHeaderGroups()?.map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <TableHead key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </TableHead>
                        ))}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {table?.getRowModel()?.rows?.length ? (
                      table?.getRowModel()?.rows?.map((row) => (
                        <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                          {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={columns?.length} className="h-24 text-center">
                          {lang.get('msg.noResults')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <DataTablePagination table={table} />
            </>
          ) : (
            <div className="grid grid-cols-6 gap-4 max1420:grid-cols-5 max1250:grid-cols-4 max1050:grid-cols-3 md900:grid-cols-2 md700:grid-cols-1">
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {React.Children.toArray(
                filteredGridData?.map((item: GridData) => (
                  <div className="flex flex-col transition-all min-w-[180px] auto-cols-min ease-in border rounded-md shadow hover:shadow-lg hover:scale-[1.02] h-fit relative">
                    {/* responses */}
                    <div className="absolute text-xs top-3.5 left-2">
                      {item?.respondents_max ? (
                        <p>
                          {item?.completed_count}/{item?.respondents_max}
                        </p>
                      ) : (
                        <p>/</p>
                      )}
                    </div>
                    {/* status */}
                    <span
                      className={cn(
                        'absolute text-xs font-semibold top-3 right-3 rounded-full px-3 py-0.5',
                        getStatusColor(item?.status),
                      )}
                    >
                      {getStatusLabel(item?.status)}
                    </span>
                    <Link
                      onClick={() => removePreviousConversationFromStorage(item?._id)}
                      to={`${pathname}${Routes.project_design.path}/${item?._id}`}
                      className="flex items-center justify-center w-full h-48 p-3 overflow-hidden font-medium text-center"
                    >
                      {item.title}
                    </Link>
                    <div className="flex items-center justify-between w-full p-3 border-t h-14">
                      <div
                        style={{ color: currentTheme?.['secondary-text'] }}
                        className="mb-1 text-sm max-w-[160px] truncate"
                      >
                        {dayjs(item?.updatedAt).format('DD/MMM/YYYY')}
                      </div>
                      <WorkspaceProjectActionsDD
                        mergedWorkspacesList={mergedWorkspacesList}
                        targettedProjectID={item?._id}
                        isUserOwner={isUserOwner}
                        isUserEditor={isUserEditor}
                      />
                    </div>
                  </div>
                )),
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
