import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { IconLoader2 } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Tabs, TabsContent } from '@/components/ui/tabs';
import { EProjectStatus } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import {
  translateDivergentViewsAndOutliers,
  translateKeyThemes,
  translateNoKeyThemesData,
  translateNoOpportunitiesData,
  translateNoSummaryData,
  translateOpportunities,
  translatePatternsAndTrends,
  translateSummary,
} from '@/pages/projects/single-project/Content/project_results/resultsPageTranslations';
import useQuestionsLoadingStore from '@/store/QuestionsLoadingStore';
import PaperAirplaneIcon from '../icons/PaperAirplaneIcon';
import { Button } from '../ui/button';
import Categories from './Categories';
import useCategoriesLoadingStore from '@/store/CategoriesLoadingStore';
import ProjectSummary from '../projects/single-project/project-summary/ProjectSummary';
import { PerQuestionProps, QuestionTabs } from '@/types';
import Transcripts from './Transcripts';
import ProjectQuestionInsights from './ProjectQuestionInsights';
import QuestionItemTabs from './QuestionItemTabs';
import { resolveTranslation } from '@/utils/resolveTranslation';

function QuestionItem({
  isPendingReRun,
  selectedQuestionInshights,
  isLoadingInshights,
  selectedQuestionTranscripts,
  questionID,
  analysisLanguage,
  singleProjectData,
  newConversationsCount,
  onReRun,
  isTranscriptsLoading,
  isRerunLoading,
}: PerQuestionProps) {
  const { lang } = useTranslation();
  const { project_id } = useParams();
  const { currentTheme } = useTheme();
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');

  const questionsLoading = useQuestionsLoadingStore((state) => state.questionsLoading);
  const categoriesLoading = useCategoriesLoadingStore((state) => state.categoriesLoading);

  const [focusedTab, setFocusedTab] = useState<'insights' | 'answers'>('insights');

  const handleTabFocus = (tab: 'insights' | 'answers') => setFocusedTab(tab);

  const tabStyle = (tab: 'insights' | 'answers') => ({
    backgroundColor: focusedTab === tab ? currentTheme?.primary : 'transparent',
    color: focusedTab === tab ? 'white' : 'black',
  });

  const noEnoughInterviewData =
    selectedQuestionInshights?.summary?.length === 0 &&
    selectedQuestionInshights?.themes?.length === 0 &&
    selectedQuestionInshights?.quotes?.length === 0 &&
    selectedQuestionInshights?.opportunities?.length === 0 &&
    selectedQuestionInshights?.quotes?.length === 0;

  const keyThemesTitle = selectedQuestionInshights?.patterns_and_trends
    ? resolveTranslation(translatePatternsAndTrends, analysisLanguage)
    : resolveTranslation(translateKeyThemes, analysisLanguage);
  const opportunitiesTitle = selectedQuestionInshights?.divergent_views_and_outliers
    ? resolveTranslation(translateDivergentViewsAndOutliers, analysisLanguage)
    : resolveTranslation(translateOpportunities, analysisLanguage);
  const isCompletedWithInsufficientDataAndFilter =
    singleProjectData?.status === EProjectStatus.COMPLETED && noEnoughInterviewData && !!filter_id;
  const isReadyWithoutQuestionsAndTranscripts = !questionsLoading && !isTranscriptsLoading;
  const isReRunInProgress = isPendingReRun || isRerunLoading;
  const dataWithStepsIsLoading = isLoadingInshights || isPendingReRun || questionsLoading;

  if (singleProjectData?.status === EProjectStatus.COMPLETED && isRerunLoading) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full min-h-[50vh] text-center">
        <Button
          variant="default"
          disabled
          className="flex items-center gap-2 mr-[4%] font-bold px-7"
        >
          <IconLoader2 className="animate-spin" size={20} />
          <p className="epilogue-font">{lang.get('msg.applyingFilter')}</p>
        </Button>
      </div>
    );
  }

  const tabs: QuestionTabs[] = [
    {
      value: 'insights',
      labelKey: 'msg.insights',
      disabled: questionsLoading,
      isLoading: false,
    },
    {
      value: 'answers',
      labelKey: 'msg.answers',
      disabled: isLoadingInshights || questionsLoading || categoriesLoading,
      isLoading: categoriesLoading,
    },
  ];

  return isCompletedWithInsufficientDataAndFilter ? (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-[50vh] text-center">
      <p className="font-medium">{lang.get('msg.noInterviewsThatMatchThisFilter')}</p>
    </div>
  ) : (
    <Tabs defaultValue="insights" className="w-full">
      {/* TABS LIST */}
      <QuestionItemTabs
        tabs={tabs}
        handleTabFocus={handleTabFocus}
        tabStyle={tabStyle}
        lang={lang}
      />

      {/* INSIGHTS CONTENT  */}
      <TabsContent value="insights" className="pb-10">
        {!noEnoughInterviewData ? (
          <div className="flex flex-col w-full gap-10 py-4 pl-1">
            {/* categories */}
            <Categories
              projectID={project_id}
              questionID={questionID}
              analysisLanguage={analysisLanguage}
            />
            {/* summary */}
            <ProjectSummary
              noSummarydata={resolveTranslation(translateNoSummaryData, analysisLanguage)}
              isLoading={isPendingReRun || isLoadingInshights || questionsLoading}
              marginBottom="!mb-4"
              title={resolveTranslation(translateSummary, analysisLanguage)}
              summary={selectedQuestionInshights?.summary}
            />

            {/* Key Themes Section */}
            <ProjectQuestionInsights
              data={
                selectedQuestionInshights?.patterns_and_trends ?? selectedQuestionInshights?.themes
              }
              title={keyThemesTitle}
              isLoading={dataWithStepsIsLoading}
              noDataText={resolveTranslation(translateNoKeyThemesData, analysisLanguage)}
            />

            {/* Opportunities Section */}
            <ProjectQuestionInsights
              data={
                selectedQuestionInshights?.divergent_views_and_outliers ??
                selectedQuestionInshights?.opportunities
              }
              title={opportunitiesTitle}
              isLoading={dataWithStepsIsLoading}
              noDataText={resolveTranslation(translateNoOpportunitiesData, analysisLanguage)}
            />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full min-h-[65vh] text-center">
            {singleProjectData?.status !== EProjectStatus.COMPLETED && (
              <Button
                variant="default"
                disabled={isReRunInProgress}
                className="relative flex items-center gap-2 mt-5 font-bold px-7"
                onClick={() => onReRun()}
              >
                {isReRunInProgress ? (
                  <IconLoader2 className="animate-spin" size={20} />
                ) : (
                  <PaperAirplaneIcon className="size-4" />
                )}
                {isReRunInProgress ? 'Running...' : 'Run'}
                {!isRerunLoading && newConversationsCount > 0 && (
                  <span className="absolute flex items-center justify-center h-5 px-1.5 text-xs text-white bg-red-600 rounded-full w-fit -top-1 -right-1.5">
                    {newConversationsCount}
                  </span>
                )}
              </Button>
            )}
          </div>
        )}
      </TabsContent>

      {/* TRANSCRIPTS CONTENT */}
      <TabsContent value="answers">
        <Transcripts
          isReady={isReadyWithoutQuestionsAndTranscripts}
          questionID={questionID}
          selectedTranscripts={selectedQuestionTranscripts}
        />
      </TabsContent>
    </Tabs>
  );
}

export default QuestionItem;
