import Squares2X2Icon from '@/components/icons/Squares2X2Icon';
import QuestionMarkCircleIcon from '@/components/icons/QuestionMarkCircleIcon';
import UserIcon from '@/components/icons/UserIcon';
import SparklesIcon from '@/components/icons/SparklesIcon';
import { ProjectTabsProps } from '@/types';
import Tab from './ProjectTab';

const tabs = [
  { value: 'overview_tab', icon: Squares2X2Icon, label: 'msg.overview' },
  { value: 'questions_tab', icon: QuestionMarkCircleIcon, label: 'msg.questions' },
  { value: 'transcripts_tab', icon: UserIcon, label: 'msg.transcripts' },
  { value: 'ai_search_tab', icon: SparklesIcon, label: 'msg.aiSearch' },
];

function ProjectTabs({ lang, currentTheme, activeTab }: ProjectTabsProps) {
  return (
    <>
      {tabs.map(({ value, icon, label }) => (
        <Tab
          key={value}
          value={value}
          icon={icon}
          label={lang.get(label)}
          currentTheme={currentTheme}
          activeTab={activeTab}
        />
      ))}
    </>
  );
}

export default ProjectTabs;
